import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Section from "../../composants/layout/Section"
import Lien from "../../composants/navigation/Lien"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "isoAstrophotographie" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["bulbeZetaOphiuchi"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "courbeQEASI2400MCPro"
            "fullWellASI2400MCPro"
            "readNoiseASI2400MCPro"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: { code: { in: ["materiel12800ISO", "materiel400ISO"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielAstrophotographie"
            "logicielsAstrophotographie"
            "galerieVoieLactee"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
  }
`

export default function ComprendreIsos() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  let images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysageTresPetit"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        On lit beaucoup de choses à propos réglages ISO. Notamment qu’une montée
        en ISO augmente le bruit des images. Vraiment ? Regardons tout cela plus
        en détails : après quelques généralités, nous terminerons par une petite
        démonstration mathématique. C’est parti !
      </Section>
      <Image
        id="bulbe-zeta-ophiuchi"
        image={obtenirImage(images, "bulbeZetaOphiuchi")}
        langue={page.langue}
        legende="Le bulbe galactique et Zeta Ophiuchi. Beaucoup de mes astrophotographies sont réalisées avec une sensibilité élevée, de 1600 à 6400 ISO."
      />
      <Section titre="Origine">
        <p>
          La sensibilité ISO désigne <strong>les règles de mesures</strong> de
          la sensibilité à la lumière des pellicules argentiques ou des capteurs
          numériques. L’appellation « ISO » provient du nom de l’organisme
          international « International Organization for Standardization » qui
          les a originellement définies. Quatre standards ISO ont été créés : un
          pour les pellicules noir et blanc, un pour les pellicules couleurs, un
          pour les pellicules inversibles (diapositives) et enfin un plus
          récemment pour les capteurs numériques.
        </p>
        <p>
          Malgré tout, on observe <strong>des comportements différents</strong>{" "}
          entre les matériels. Elles peuvent s’expliquer par des interprétations
          de la norme pouvant varier lors des processus de conception et de
          fabrication des capteurs.
        </p>
      </Section>
      <Section titre="Effets">
        <p>Lors d’une montée en ISO, les effets suivants se produisent :</p>
        <ul>
          <li>
            <strong>Votre image est plus lumineuse</strong> : la mesure de la
            quantité de photons reçue par le capteur de votre appareil est
            amplifiée ; dans le jargon électronique, on appelle cela le{" "}
            <strong>gain</strong>. Retenez ce terme car nous nous en servirons
            souvent dans la suite de cet article.
          </li>
          <li>
            <strong>Votre image perd en dynamique</strong> : à cause de
            l’amplification, les hautes lumières saturent plus rapidement. Il
            faut donc éviter de trop monter en ISO car il est nécessaire d’avoir
            un maximum de dynamique pour exposer correctement les hautes et
            basses lumières.
          </li>
          <li>
            <strong>
              Le bruit de lecture des données du capteur de l’appareil diminue.
            </strong>{" "}
            Ce type de bruit est celui qui nous intéresse dans le cadre ce
            chapitre. Il existe d’autres sources de bruit, mais elles ne
            dépendent pas des réglages de votre boîtier (bruit de photons, bruit
            thermique, …).
          </li>
        </ul>
      </Section>
      <Section titre="Fausses croyances">
        <p>
          Contrairement à ce que l’on croit, une montée en ISO{" "}
          <strong>ne rend pas votre appareil plus sensible</strong> et{" "}
          <strong>ne fait pas monter le bruit de vos images</strong>. Au
          contraire, elle le fait baisser.
        </p>
        <p>
          <strong>Voici un exemple concret</strong> : un Canon EOS Ra a posé
          pendant 15 secondes à la même ouverture sur un ciel étoilé au Chili
          avec deux réglages ISO différents : 400 ISO et 12800 ISO. La quantité
          d’informations reçue (le signal) est donc la même entre les deux
          clichés. L’image à 400 ISO a ensuite été éclaircie dans Lightroom afin
          d’obtenir la même luminosité que l’image à 12800 ISO (+ 5 EV).
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "materiel400ISO")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materiel12800ISO")}
            langue={page.langue}
          />
        </div>
      </Section>
      <p>
        Le constat est clair : l’image à 12800 ISO{" "}
        <strong>n’est pas plus bruitée</strong> que l’image à 400 ISO et elle
        est même meilleure dans le petit buisson sombre. Pour mieux comprendre
        ce qu’il se passe, nous devons nous plonger dans quelques graphiques.
        C’est parti.
      </p>
      <Section titre="Le comportement des capteurs de nos appareils photo et caméras">
        <p>
          Pour comprendre ce qu’il se passe quand on monte en ISOs, nous devons
          nous plonger dans quelques mesures. Celles que nous allons étudier ont
          été réalisées par le fabriquant de caméras d’astronomie ZWO sur un
          capteur Sony IMX 410 qui équipe la caméra{" "}
          <Lien urlExterne="https://astronomy-imaging-camera.com/product/asi2400mc-pro/">
            ASI 2400 MC Pro
          </Lien>{" "}
          (son homologue chez QHY est la caméra{" "}
          <Lien urlExterne="https://www.qhyccd.com/astronomical-camera-qhy410c/">
            QHY 410C
          </Lien>
          ). Il m’intéresse pour cette démonstration car il équipe aussi les
          appareils Nikon Z6, Z6II, Sony Alpha 7III.
        </p>
        <h3>Le fonctionnement d’un capteur</h3>
        <p>
          Avant de poursuivre, quelques mots sur le fonctionnement d’un capteur
          :
          <ul>
            <li>
              <strong>1. Pendant la pose</strong>, les photons sont convertis en
              électrons grâce à{" "}
              <Lien urlExterne="https://fr.wikipedia.org/wiki/Effet_photo%C3%A9lectrique">
                l’effet photoélectrique
              </Lien>
              . Cette opération se réalise grâce à un substrat qui libère des
              électrons en fonction de la charge photonique reçue. Elle se
              réalise avec plus ou moins d’efficacité (on parle{" "}
              <Lien urlExterne="https://fr.wikipedia.org/wiki/Efficacit%C3%A9_quantique">
                d’efficacité quantique
              </Lien>
              ). Elle est fonction de la longueur d’onde (donc de la couleur)
              comme le montre le graphique ci-dessous. Pour un capteur qui a un
              rendement moyen de 50 %, si deux photons sont captés par un
              photosite pendant une pose alors un électron sera libéré.
              <Image
                image={obtenirImage(images, "courbeQEASI2400MCPro")}
                langue={page.langue}
              />
            </li>
            <li>
              <strong>2. A la fin de la pose</strong>, la charge électronique de
              chaque photosite est amplifiée puis convertie en signal numérique
              à l’aide d’un <strong>ADC (Analog to Digital Converter)</strong>.
              Monter le gain ou les ISO signifie donc appliquer un coefficient
              multiplicateur lors de la lecture de la charge électronique d’un
              photosite. Pour un gain de 2, si un photosite contient 20
              électrons, alors la valeur lue sera de 40 électrons.
            </li>
          </ul>
        </p>
        <h3>Le full well</h3>
        <p>
          Le full well, ou capacité d’un puits de potentiel, désigne la capacité
          de charge électronique de chaque photosite d’un capteur. Lorsque le
          gain augmente, la charge électronique lue est amplifiée ce qui sature
          l’ADC plus rapidement. Le graphique ci-dessous montre cet effet : pour
          un gain de 200, l’ADC de la caméra sera saturé dès que le puits de
          potentiel atteindra 10k e-.
        </p>
        <Image
          image={obtenirImage(images, "fullWellASI2400MCPro")}
          langue={page.langue}
        />
        <p>
          Pour mieux comprendre le phénomène, je vous invite à lire cette{" "}
          <Lien urlExterne="https://www.youtube.com/watch?v=97vUB-5HfVE">
            excellente vidéo
          </Lien>{" "}
          réalisée par Steve Chambers du fabriquant de caméras Atik.
        </p>
        <h3>Le bruit de lecture</h3>
        <p>
          Le bruit de lecture est introduit comme son nom l’indique à chaque
          lecture du capteur. Il est introduit par l’amplificateur et l’ADC.
          Mais paradoxalement, plus le gain augmente, plus le bruit de lecture
          diminue. Le graphique ci-dessous illustre ce phénomène.
        </p>
        <Image
          image={obtenirImage(images, "readNoiseASI2400MCPro")}
          langue={page.langue}
        />
        <p>
          Cependant, le bruit de lecture ne diminue presque plus à partir d’un
          certain gain, ici 140 dB. Vous saturerez donc inutilement votre ADC si
          vous montez au-delà.
        </p>

        <p>
          Nous tenons donc là une <strong>première explication</strong> du
          comportement que vous avez observé dans les deux vignettes
          comparatives 400 et 12800 ISO du début d’article :{" "}
          <strong>le bruit de lecture diminue lorsque le gain augmente</strong>,
          ce qui explique pourquoi la vignette à 12800 ISO est moins bruitée.
        </p>
      </Section>
      <Section titre="Quelques calculs">
        <p>
          Pour aller au bout du geste et finir de comprendre pourquoi la
          vignette à 12800 ISO est moins bruitée que la vignette à 400 ISO, il
          est temps de faire un peu de mathématiques car tout n’est pas qu’une
          histoire de bruit de lecture. Il n’y a rien de très compliqué sinon je
          n’aurais pas écrit cet article !
        </p>
        <h3>Ce qui compte vraiment, c’est le rapport signal / bruit</h3>
        <p>
          Si la vignette à 12800 ISO vue en début d’article est moins bruitée,
          c’est parce que le{" "}
          <strong>rapport signal / bruit est meilleur</strong>. Mais voyons
          quelle influence ont le gain et les ISO sur le rapport signal / bruit.
          Par convention, celui-ci est nommé <strong>RSB</strong>.
        </p>
        <h3>Le bruit</h3>
        <p>
          Tout signal est lié à un bruit. Lorsque le signal augmente, le bruit
          augmente également, mais dans une proportion moindre :{" "}
          <strong>par la racine carrée du signal</strong>. Par conséquent :{" "}
        </p>
        <ul>
          <li>
            <p>Pour un signal de 100, le RSB est égal à :</p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="83.463843pt"
              height="13.772293pt"
              viewBox="-.299738 -.251206 83.463843 13.772293"
            >
              <defs>
                <path
                  id="g0-48"
                  d="M5.770907-3.479999C5.770907-4.538181 5.759998-7.145453 3.130908-7.145453S.490909-4.54909 .490909-3.479999C.490909-2.399999 .501818 .12 3.130908 .12C5.749089 .12 5.770907-2.38909 5.770907-3.479999ZM3.130908-.272727C2.738181-.272727 2.127272-.512727 1.985454-1.309091C1.887272-1.876363 1.887272-3.010908 1.887272-3.610908C1.887272-4.396362 1.887272-5.105453 1.974545-5.672726C2.105454-6.643635 2.86909-6.752725 3.130908-6.752725C3.534544-6.752725 3.981817-6.545453 4.18909-6.065453C4.374544-5.661817 4.374544-4.505453 4.374544-3.610908C4.374544-2.999999 4.374544-2.007272 4.298181-1.44C4.145453-.425454 3.458181-.272727 3.130908-.272727Z"
                />
                <path
                  id="g0-49"
                  d="M3.861817-6.796362C3.861817-7.036362 3.861817-7.145453 3.578181-7.145453C3.458181-7.145453 3.436363-7.145453 3.338181-7.069089C2.498181-6.447271 1.374545-6.447271 1.145454-6.447271H.927272V-5.934544H1.145454C1.32-5.934544 1.919999-5.945453 2.563636-6.152726V-.512727H1.047272V0C1.527272-.032727 2.683636-.032727 3.218181-.032727S4.90909-.032727 5.389089 0V-.512727H3.861817V-6.796362Z"
                />
                <path
                  id="g1-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g2-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g3-48"
                  d="M5.01818-3.490908C5.01818-4.363635 4.963635-5.236362 4.581817-6.043635C4.079999-7.090907 3.185454-7.265453 2.727272-7.265453C2.072727-7.265453 1.276363-6.981816 .829091-5.967271C.48-5.214544 .425454-4.363635 .425454-3.490908C.425454-2.672727 .469091-1.690909 .916363-.861818C1.385454 .021818 2.181818 .24 2.716363 .24C3.305454 .24 4.134544 .010909 4.614544-1.025454C4.963635-1.778181 5.01818-2.62909 5.01818-3.490908ZM2.716363 0C2.290908 0 1.647272-.272727 1.450909-1.32C1.330909-1.974545 1.330909-2.978181 1.330909-3.621817C1.330909-4.319999 1.330909-5.039999 1.418181-5.629089C1.625454-6.927271 2.443636-7.025453 2.716363-7.025453C3.076363-7.025453 3.796363-6.829089 4.003635-5.749089C4.112726-5.13818 4.112726-4.30909 4.112726-3.621817C4.112726-2.803636 4.112726-2.061818 3.992726-1.363636C3.82909-.327273 3.207272 0 2.716363 0Z"
                />
                <path
                  id="g3-49"
                  d="M3.207272-6.981816C3.207272-7.243634 3.207272-7.265453 2.956363-7.265453C2.279999-6.567271 1.32-6.567271 .970909-6.567271V-6.229089C1.189091-6.229089 1.832727-6.229089 2.399999-6.512725V-.861818C2.399999-.469091 2.367272-.338182 1.385454-.338182H1.036363V0C1.418181-.032727 2.367272-.032727 2.803636-.032727S4.18909-.032727 4.570908 0V-.338182H4.221817C3.239999-.338182 3.207272-.458182 3.207272-.861818V-6.981816Z"
                />
                <path
                  id="g3-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -67.239435)">
                <use x="70.734745" y="68.742217" xlinkHref="#g3-49" />
                <use x="76.189309" y="68.742217" xlinkHref="#g3-48" />
                <use x="81.643874" y="68.742217" xlinkHref="#g3-48" />
                <use x="87.098439" y="68.742217" xlinkHref="#g2-61" />
                <use x="92.553004" y="59.717983" xlinkHref="#g1-112" />
                <rect
                  x="101.643928"
                  y="59.281631"
                  height=".436352"
                  width="16.363655"
                />
                <use x="101.643928" y="68.742217" xlinkHref="#g3-49" />
                <use x="107.098493" y="68.742217" xlinkHref="#g3-48" />
                <use x="112.553057" y="68.742217" xlinkHref="#g3-48" />
                <use x="121.03783" y="68.742217" xlinkHref="#g3-61" />
                <use x="132.552953" y="68.742217" xlinkHref="#g0-49" />
                <use x="138.825646" y="68.742217" xlinkHref="#g0-48" />
              </g>
            </svg>
          </li>
          <li>
            <p>Pour un signal de 200, le RSB est égal à :</p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="94.058388pt"
              height="13.772293pt"
              viewBox="-.299738 -.251206 94.058388 13.772293"
            >
              <defs>
                <path
                  id="g0-44"
                  d="M2.334545-.218182C2.247272 .970909 1.538181 1.54909 1.309091 1.745454C1.243636 1.799999 1.210909 1.832727 1.210909 1.898181C1.210909 2.007272 1.330909 2.116363 1.418181 2.116363C1.581818 2.116363 2.727272 1.178181 2.727272-.338182C2.727272-1.08 2.356363-1.701818 1.723636-1.701818C1.221818-1.701818 .883636-1.32 .883636-.850909C.883636-.414545 1.178181 0 1.734545 0C2.083636 0 2.301818-.185454 2.334545-.218182Z"
                />
                <path
                  id="g0-49"
                  d="M3.861817-6.796362C3.861817-7.036362 3.861817-7.145453 3.578181-7.145453C3.458181-7.145453 3.436363-7.145453 3.338181-7.069089C2.498181-6.447271 1.374545-6.447271 1.145454-6.447271H.927272V-5.934544H1.145454C1.32-5.934544 1.919999-5.945453 2.563636-6.152726V-.512727H1.047272V0C1.527272-.032727 2.683636-.032727 3.218181-.032727S4.90909-.032727 5.389089 0V-.512727H3.861817V-6.796362Z"
                />
                <path
                  id="g0-52"
                  d="M4.854544-1.701818H5.912726V-2.214545H4.854544V-6.807271C4.854544-7.101816 4.832726-7.156362 4.527271-7.156362C4.298181-7.156362 4.287272-7.145453 4.156362-6.981816L.349091-2.214545V-1.701818H3.490908V-.512727H2.26909V0C2.683636-.032727 3.676363-.032727 4.145453-.032727C4.581817-.032727 5.530908-.032727 5.912726 0V-.512727H4.854544V-1.701818ZM3.610908-2.214545H.916363L3.610908-5.596362V-2.214545Z"
                />
                <path
                  id="g1-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g2-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g3-48"
                  d="M5.01818-3.490908C5.01818-4.363635 4.963635-5.236362 4.581817-6.043635C4.079999-7.090907 3.185454-7.265453 2.727272-7.265453C2.072727-7.265453 1.276363-6.981816 .829091-5.967271C.48-5.214544 .425454-4.363635 .425454-3.490908C.425454-2.672727 .469091-1.690909 .916363-.861818C1.385454 .021818 2.181818 .24 2.716363 .24C3.305454 .24 4.134544 .010909 4.614544-1.025454C4.963635-1.778181 5.01818-2.62909 5.01818-3.490908ZM2.716363 0C2.290908 0 1.647272-.272727 1.450909-1.32C1.330909-1.974545 1.330909-2.978181 1.330909-3.621817C1.330909-4.319999 1.330909-5.039999 1.418181-5.629089C1.625454-6.927271 2.443636-7.025453 2.716363-7.025453C3.076363-7.025453 3.796363-6.829089 4.003635-5.749089C4.112726-5.13818 4.112726-4.30909 4.112726-3.621817C4.112726-2.803636 4.112726-2.061818 3.992726-1.363636C3.82909-.327273 3.207272 0 2.716363 0Z"
                />
                <path
                  id="g3-50"
                  d="M1.385454-.84L2.541817-1.963636C4.243635-3.46909 4.89818-4.058181 4.89818-5.149089C4.89818-6.392725 3.916363-7.265453 2.585454-7.265453C1.352727-7.265453 .545454-6.261816 .545454-5.290908C.545454-4.679999 1.090909-4.679999 1.123636-4.679999C1.309091-4.679999 1.690909-4.810908 1.690909-5.25818C1.690909-5.541817 1.494545-5.825453 1.112727-5.825453C1.025454-5.825453 1.003636-5.825453 .970909-5.814544C1.221818-6.523635 1.810909-6.927271 2.443636-6.927271C3.436363-6.927271 3.905453-6.043635 3.905453-5.149089C3.905453-4.276362 3.359999-3.414545 2.759999-2.738181L.665454-.403636C.545454-.283636 .545454-.261818 .545454 0H4.592726L4.89818-1.898181H4.625453C4.570908-1.570909 4.494544-1.090909 4.385453-.927272C4.30909-.84 3.58909-.84 3.34909-.84H1.385454Z"
                />
                <path
                  id="g3-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -67.239435)">
                <use x="70.734745" y="68.742217" xlinkHref="#g3-50" />
                <use x="76.189309" y="68.742217" xlinkHref="#g3-48" />
                <use x="81.643874" y="68.742217" xlinkHref="#g3-48" />
                <use x="87.098439" y="68.742217" xlinkHref="#g2-61" />
                <use x="92.553004" y="59.717983" xlinkHref="#g1-112" />
                <rect
                  x="101.643928"
                  y="59.281631"
                  height=".436352"
                  width="16.363655"
                />
                <use x="101.643928" y="68.742217" xlinkHref="#g3-50" />
                <use x="107.098493" y="68.742217" xlinkHref="#g3-48" />
                <use x="112.553057" y="68.742217" xlinkHref="#g3-48" />
                <use x="121.03783" y="68.742217" xlinkHref="#g3-61" />
                <use x="132.552953" y="68.742217" xlinkHref="#g0-49" />
                <use x="138.825646" y="68.742217" xlinkHref="#g0-52" />
                <use x="145.098338" y="68.742217" xlinkHref="#g0-44" />
                <use x="148.583167" y="68.742217" xlinkHref="#g0-49" />
              </g>
            </svg>
          </li>
          <li>
            <p>Pour un signal de 400, le RSB est égal à :</p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="83.463843pt"
              height="13.772293pt"
              viewBox="-.299738 -.251206 83.463843 13.772293"
            >
              <defs>
                <path
                  id="g0-48"
                  d="M5.770907-3.479999C5.770907-4.538181 5.759998-7.145453 3.130908-7.145453S.490909-4.54909 .490909-3.479999C.490909-2.399999 .501818 .12 3.130908 .12C5.749089 .12 5.770907-2.38909 5.770907-3.479999ZM3.130908-.272727C2.738181-.272727 2.127272-.512727 1.985454-1.309091C1.887272-1.876363 1.887272-3.010908 1.887272-3.610908C1.887272-4.396362 1.887272-5.105453 1.974545-5.672726C2.105454-6.643635 2.86909-6.752725 3.130908-6.752725C3.534544-6.752725 3.981817-6.545453 4.18909-6.065453C4.374544-5.661817 4.374544-4.505453 4.374544-3.610908C4.374544-2.999999 4.374544-2.007272 4.298181-1.44C4.145453-.425454 3.458181-.272727 3.130908-.272727Z"
                />
                <path
                  id="g0-50"
                  d="M5.639998-2.421818H5.127271C5.094544-2.203636 4.996362-1.505454 4.843635-1.429091C4.723635-1.363636 3.905453-1.363636 3.730908-1.363636H2.127272C2.639999-1.78909 3.207272-2.258181 3.698181-2.618181C4.941817-3.534544 5.639998-4.047272 5.639998-5.083635C5.639998-6.33818 4.505453-7.145453 2.967272-7.145453C1.647272-7.145453 .621818-6.469089 .621818-5.487271C.621818-4.843635 1.145454-4.679999 1.407272-4.679999C1.756363-4.679999 2.192727-4.919999 2.192727-5.465453C2.192727-6.032726 1.734545-6.207271 1.570909-6.250907C1.887272-6.512725 2.301818-6.632725 2.694545-6.632725C3.632726-6.632725 4.134544-5.890907 4.134544-5.072726C4.134544-4.319999 3.719999-3.578181 2.956363-2.803636L.763636-.567273C.621818-.436364 .621818-.414545 .621818-.196364V0H5.301817L5.639998-2.421818Z"
                />
                <path
                  id="g1-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g2-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g3-48"
                  d="M5.01818-3.490908C5.01818-4.363635 4.963635-5.236362 4.581817-6.043635C4.079999-7.090907 3.185454-7.265453 2.727272-7.265453C2.072727-7.265453 1.276363-6.981816 .829091-5.967271C.48-5.214544 .425454-4.363635 .425454-3.490908C.425454-2.672727 .469091-1.690909 .916363-.861818C1.385454 .021818 2.181818 .24 2.716363 .24C3.305454 .24 4.134544 .010909 4.614544-1.025454C4.963635-1.778181 5.01818-2.62909 5.01818-3.490908ZM2.716363 0C2.290908 0 1.647272-.272727 1.450909-1.32C1.330909-1.974545 1.330909-2.978181 1.330909-3.621817C1.330909-4.319999 1.330909-5.039999 1.418181-5.629089C1.625454-6.927271 2.443636-7.025453 2.716363-7.025453C3.076363-7.025453 3.796363-6.829089 4.003635-5.749089C4.112726-5.13818 4.112726-4.30909 4.112726-3.621817C4.112726-2.803636 4.112726-2.061818 3.992726-1.363636C3.82909-.327273 3.207272 0 2.716363 0Z"
                />
                <path
                  id="g3-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g3-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -67.239435)">
                <use x="70.734745" y="68.742217" xlinkHref="#g3-52" />
                <use x="76.189309" y="68.742217" xlinkHref="#g3-48" />
                <use x="81.643874" y="68.742217" xlinkHref="#g3-48" />
                <use x="87.098439" y="68.742217" xlinkHref="#g2-61" />
                <use x="92.553004" y="59.717983" xlinkHref="#g1-112" />
                <rect
                  x="101.643928"
                  y="59.281631"
                  height=".436352"
                  width="16.363655"
                />
                <use x="101.643928" y="68.742217" xlinkHref="#g3-52" />
                <use x="107.098493" y="68.742217" xlinkHref="#g3-48" />
                <use x="112.553057" y="68.742217" xlinkHref="#g3-48" />
                <use x="121.03783" y="68.742217" xlinkHref="#g3-61" />
                <use x="132.552953" y="68.742217" xlinkHref="#g0-50" />
                <use x="138.825646" y="68.742217" xlinkHref="#g0-48" />
              </g>
            </svg>
          </li>
        </ul>
        <p>
          <strong>Conclusion intermédiaire</strong> : pour obtenir une image
          deux fois meilleure qu’un temps de pose donné, il faut poser 4 fois
          plus.
        </p>
        <h3>Les différents types de bruits</h3>
        <p>
          Maintenant que nous savons comment calculer le rapport signal / bruit,
          il est temps de nous intéresser au bruit lui-même. Il vient d’un
          ensemble de bruits qui s’ajoutent les uns aux autres : le bruit de
          photons, le bruit thermique, le bruit de lecture et le bruit de
          quantification. Je ne m’intéresserai pas à celui-ci car cela nous
          emmènerait trop loin.
        </p>
        <h3>Le bruit de photons</h3>
        <p>
          Ce bruit est lié à la nature de la lumière. En effet, les photons nous
          parviennent de façon dispersée : il faut comparer ce phénomène à une
          pluie. La quantité de photons arrivant sur un photosite varie donc
          avec le temps. Cela se constate forcément sur les objets célestes qui
          sont peu brillants pour lesquels le flux photonique est faible. Pour
          compenser, il faut poser longtemps pour obtenir une image nette de
          l’objet.
        </p>
        <h3>Le bruit de lecture</h3>
        <p>
          Ce bruit est introduit à chaque fois qu’un capteur est lu. Nous venons
          de le voir, il varie en fonction du gain (des ISO) utilisé.
        </p>
        <h3>Le bruit thermique</h3>
        <p>
          Ce bruit est introduit lors du fonctionnement du capteur. Lorsque
          celui-ci est en pose, des électrons peuvent sauter d’un puits de
          potentiel à l’autre. Ce phénomène augmente avec la température
          ambiante, c’est pourquoi on cherche à refroidir les caméras. Nous
          mettrons de côté ce bruit pour la démonstration qui vient.
        </p>
        <h3>L’addition des bruits</h3>
        <p>
          Pour additionner les bruits il est nécessaire de réaliser une{" "}
          <strong>somme quadratique</strong> :
        </p>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="289.73967pt"
          height="13.50934pt"
          viewBox="-.299738 -.24742 289.73967 13.50934"
        >
          <defs>
            <path
              id="g2-50"
              d="M2.247572-1.625903C2.375093-1.745455 2.709838-2.008468 2.83736-2.12005C3.331507-2.574346 3.801743-3.012702 3.801743-3.737983C3.801743-4.686426 3.004732-5.300125 2.008468-5.300125C1.052055-5.300125 .422416-4.574844 .422416-3.865504C.422416-3.474969 .73325-3.419178 .844832-3.419178C1.012204-3.419178 1.259278-3.53873 1.259278-3.841594C1.259278-4.25604 .860772-4.25604 .765131-4.25604C.996264-4.837858 1.530262-5.037111 1.920797-5.037111C2.662017-5.037111 3.044583-4.407472 3.044583-3.737983C3.044583-2.909091 2.462765-2.303362 1.522291-1.338979L.518057-.302864C.422416-.215193 .422416-.199253 .422416 0H3.57061L3.801743-1.42665H3.55467C3.53076-1.267248 3.466999-.868742 3.371357-.71731C3.323537-.653549 2.717808-.653549 2.590286-.653549H1.171606L2.247572-1.625903Z"
            />
            <path
              id="g0-112"
              d="M4.65056 10.221669L2.546451 5.571108C2.462765 5.379826 2.402989 5.379826 2.367123 5.379826C2.355168 5.379826 2.295392 5.379826 2.163885 5.475467L1.028144 6.336239C.872727 6.455791 .872727 6.491656 .872727 6.527522C.872727 6.587298 .908593 6.659029 .992279 6.659029C1.06401 6.659029 1.267248 6.491656 1.398755 6.396015C1.470486 6.336239 1.649813 6.204732 1.78132 6.109091L4.136488 11.285679C4.220174 11.476961 4.27995 11.476961 4.387547 11.476961C4.566874 11.476961 4.60274 11.40523 4.686426 11.237858L10.114072 0C10.197758-.167372 10.197758-.215193 10.197758-.239103C10.197758-.358655 10.102117-.478207 9.958655-.478207C9.863014-.478207 9.779328-.418431 9.683686-.227148L4.65056 10.221669Z"
            />
            <path
              id="g3-43"
              d="M4.770112-2.761644H8.069738C8.237111-2.761644 8.452304-2.761644 8.452304-2.976837C8.452304-3.203985 8.249066-3.203985 8.069738-3.203985H4.770112V-6.503611C4.770112-6.670984 4.770112-6.886177 4.554919-6.886177C4.327771-6.886177 4.327771-6.682939 4.327771-6.503611V-3.203985H1.028144C.860772-3.203985 .645579-3.203985 .645579-2.988792C.645579-2.761644 .848817-2.761644 1.028144-2.761644H4.327771V.537983C4.327771 .705355 4.327771 .920548 4.542964 .920548C4.770112 .920548 4.770112 .71731 4.770112 .537983V-2.761644Z"
            />
            <path
              id="g3-61"
              d="M8.069738-3.873474C8.237111-3.873474 8.452304-3.873474 8.452304-4.088667C8.452304-4.315816 8.249066-4.315816 8.069738-4.315816H1.028144C.860772-4.315816 .645579-4.315816 .645579-4.100623C.645579-3.873474 .848817-3.873474 1.028144-3.873474H8.069738ZM8.069738-1.649813C8.237111-1.649813 8.452304-1.649813 8.452304-1.865006C8.452304-2.092154 8.249066-2.092154 8.069738-2.092154H1.028144C.860772-2.092154 .645579-2.092154 .645579-1.876961C.645579-1.649813 .848817-1.649813 1.028144-1.649813H8.069738Z"
            />
            <path
              id="g1-58"
              d="M2.199751-.573848C2.199751-.920548 1.912827-1.159651 1.625903-1.159651C1.279203-1.159651 1.0401-.872727 1.0401-.585803C1.0401-.239103 1.327024 0 1.613948 0C1.960648 0 2.199751-.286924 2.199751-.573848Z"
            />
            <path
              id="g1-65"
              d="M2.032379-1.327024C1.613948-.621669 1.207472-.382565 .633624-.3467C.502117-.334745 .406476-.334745 .406476-.119552C.406476-.047821 .466252 0 .549938 0C.765131 0 1.303113-.02391 1.518306-.02391C1.865006-.02391 2.247572 0 2.582316 0C2.654047 0 2.797509 0 2.797509-.227148C2.797509-.334745 2.701868-.3467 2.630137-.3467C2.355168-.37061 2.12802-.466252 2.12802-.753176C2.12802-.920548 2.199751-1.052055 2.355168-1.315068L3.263761-2.82142H6.312329C6.324284-2.713823 6.324284-2.618182 6.336239-2.510585C6.372105-2.199751 6.515567-.956413 6.515567-.729265C6.515567-.37061 5.905853-.3467 5.71457-.3467C5.583064-.3467 5.451557-.3467 5.451557-.131507C5.451557 0 5.559153 0 5.630884 0C5.834122 0 6.073225-.02391 6.276463-.02391H6.957908C7.687173-.02391 8.2132 0 8.225156 0C8.308842 0 8.440349 0 8.440349-.227148C8.440349-.3467 8.332752-.3467 8.153425-.3467C7.49589-.3467 7.483935-.454296 7.44807-.812951L6.718804-8.272976C6.694894-8.51208 6.647073-8.53599 6.515567-8.53599C6.396015-8.53599 6.324284-8.51208 6.216687-8.332752L2.032379-1.327024ZM3.466999-3.16812L5.869988-7.185056L6.276463-3.16812H3.466999Z"
            />
            <path
              id="g1-66"
              d="M4.375592-7.352428C4.483188-7.79477 4.531009-7.81868 4.99726-7.81868H6.551432C7.902366-7.81868 7.902366-6.670984 7.902366-6.563387C7.902366-5.595019 6.933998-4.363636 5.355915-4.363636H3.634371L4.375592-7.352428ZM6.396015-4.267995C7.699128-4.507098 8.88269-5.415691 8.88269-6.515567C8.88269-7.44807 8.057783-8.16538 6.706849-8.16538H2.86924C2.642092-8.16538 2.534496-8.16538 2.534496-7.938232C2.534496-7.81868 2.642092-7.81868 2.82142-7.81868C3.550685-7.81868 3.550685-7.723039 3.550685-7.591532C3.550685-7.567621 3.550685-7.49589 3.502864-7.316563L1.888917-.884682C1.78132-.466252 1.75741-.3467 .920548-.3467C.6934-.3467 .573848-.3467 .573848-.131507C.573848 0 .645579 0 .884682 0H4.985305C6.814446 0 8.225156-1.3868 8.225156-2.594271C8.225156-3.574595 7.364384-4.172354 6.396015-4.267995ZM4.698381-.3467H3.084433C2.917061-.3467 2.893151-.3467 2.82142-.358655C2.689913-.37061 2.677958-.394521 2.677958-.490162C2.677958-.573848 2.701868-.645579 2.725778-.753176L3.56264-4.124533H5.810212C7.220922-4.124533 7.220922-2.809465 7.220922-2.713823C7.220922-1.566127 6.180822-.3467 4.698381-.3467Z"
            />
            <path
              id="g1-78"
              d="M8.846824-6.910087C8.978331-7.424159 9.169614-7.782814 10.078207-7.81868C10.114072-7.81868 10.257534-7.830635 10.257534-8.033873C10.257534-8.16538 10.149938-8.16538 10.102117-8.16538C9.863014-8.16538 9.2533-8.141469 9.014197-8.141469H8.440349C8.272976-8.141469 8.057783-8.16538 7.890411-8.16538C7.81868-8.16538 7.675218-8.16538 7.675218-7.938232C7.675218-7.81868 7.770859-7.81868 7.854545-7.81868C8.571856-7.79477 8.619676-7.519801 8.619676-7.304608C8.619676-7.197011 8.607721-7.161146 8.571856-6.993773L7.220922-1.601993L4.662516-7.962142C4.578829-8.153425 4.566874-8.16538 4.303861-8.16538H2.84533C2.606227-8.16538 2.49863-8.16538 2.49863-7.938232C2.49863-7.81868 2.582316-7.81868 2.809465-7.81868C2.86924-7.81868 3.574595-7.81868 3.574595-7.711083C3.574595-7.687173 3.550685-7.591532 3.53873-7.555666L1.948692-1.219427C1.80523-.633624 1.518306-.382565 .729265-.3467C.669489-.3467 .549938-.334745 .549938-.119552C.549938 0 .669489 0 .705355 0C.944458 0 1.554172-.02391 1.793275-.02391H2.367123C2.534496-.02391 2.737733 0 2.905106 0C2.988792 0 3.120299 0 3.120299-.227148C3.120299-.334745 3.000747-.3467 2.952927-.3467C2.558406-.358655 2.175841-.430386 2.175841-.860772C2.175841-.956413 2.199751-1.06401 2.223661-1.159651L3.837609-7.555666C3.90934-7.436115 3.90934-7.412204 3.957161-7.304608L6.802491-.215193C6.862267-.071731 6.886177 0 6.993773 0C7.113325 0 7.12528-.035866 7.173101-.239103L8.846824-6.910087Z"
            />
            <path
              id="g1-84"
              d="M4.985305-7.292653C5.057036-7.579577 5.080946-7.687173 5.260274-7.734994C5.355915-7.758904 5.750436-7.758904 6.001494-7.758904C7.197011-7.758904 7.758904-7.711083 7.758904-6.77858C7.758904-6.599253 7.711083-6.144956 7.639352-5.702615L7.627397-5.559153C7.627397-5.511333 7.675218-5.439601 7.746949-5.439601C7.866501-5.439601 7.866501-5.499377 7.902366-5.69066L8.249066-7.806725C8.272976-7.914321 8.272976-7.938232 8.272976-7.974097C8.272976-8.105604 8.201245-8.105604 7.962142-8.105604H1.422665C1.147696-8.105604 1.135741-8.093649 1.06401-7.878456L.334745-5.726526C.32279-5.702615 .286924-5.571108 .286924-5.559153C.286924-5.499377 .334745-5.439601 .406476-5.439601C.502117-5.439601 .526027-5.487422 .573848-5.642839C1.075965-7.089415 1.327024-7.758904 2.917061-7.758904H3.718057C4.004981-7.758904 4.124533-7.758904 4.124533-7.627397C4.124533-7.591532 4.124533-7.567621 4.064757-7.352428L2.462765-.932503C2.343213-.466252 2.319303-.3467 1.052055-.3467C.753176-.3467 .669489-.3467 .669489-.119552C.669489 0 .800996 0 .860772 0C1.159651 0 1.470486-.02391 1.769365-.02391H3.634371C3.93325-.02391 4.25604 0 4.554919 0C4.686426 0 4.805978 0 4.805978-.227148C4.805978-.3467 4.722291-.3467 4.411457-.3467C3.335492-.3467 3.335492-.454296 3.335492-.633624C3.335492-.645579 3.335492-.729265 3.383313-.920548L4.985305-7.292653Z"
            />
            <path
              id="g1-97"
              d="M3.598506-1.422665C3.53873-1.219427 3.53873-1.195517 3.371357-.968369C3.108344-.633624 2.582316-.119552 2.020423-.119552C1.530262-.119552 1.255293-.561893 1.255293-1.267248C1.255293-1.924782 1.625903-3.263761 1.853051-3.765878C2.259527-4.60274 2.82142-5.033126 3.287671-5.033126C4.076712-5.033126 4.23213-4.052802 4.23213-3.957161C4.23213-3.945205 4.196264-3.789788 4.184309-3.765878L3.598506-1.422665ZM4.363636-4.483188C4.23213-4.794022 3.90934-5.272229 3.287671-5.272229C1.936737-5.272229 .478207-3.526775 .478207-1.75741C.478207-.573848 1.171606 .119552 1.984558 .119552C2.642092 .119552 3.203985-.394521 3.53873-.789041C3.658281-.083686 4.220174 .119552 4.578829 .119552S5.224408-.095641 5.439601-.526027C5.630884-.932503 5.798257-1.661768 5.798257-1.709589C5.798257-1.769365 5.750436-1.817186 5.678705-1.817186C5.571108-1.817186 5.559153-1.75741 5.511333-1.578082C5.332005-.872727 5.104857-.119552 4.614695-.119552C4.267995-.119552 4.244085-.430386 4.244085-.669489C4.244085-.944458 4.27995-1.075965 4.387547-1.542217C4.471233-1.841096 4.531009-2.10411 4.62665-2.450809C5.068991-4.244085 5.176588-4.674471 5.176588-4.746202C5.176588-4.913574 5.045081-5.045081 4.865753-5.045081C4.483188-5.045081 4.387547-4.62665 4.363636-4.483188Z"
            />
            <path
              id="g1-98"
              d="M2.761644-7.998007C2.773599-8.045828 2.797509-8.117559 2.797509-8.177335C2.797509-8.296887 2.677958-8.296887 2.654047-8.296887C2.642092-8.296887 2.211706-8.261021 1.996513-8.237111C1.793275-8.225156 1.613948-8.201245 1.398755-8.18929C1.111831-8.16538 1.028144-8.153425 1.028144-7.938232C1.028144-7.81868 1.147696-7.81868 1.267248-7.81868C1.876961-7.81868 1.876961-7.711083 1.876961-7.591532C1.876961-7.507846 1.78132-7.161146 1.733499-6.945953L1.446575-5.798257C1.327024-5.32005 .645579-2.606227 .597758-2.391034C.537983-2.092154 .537983-1.888917 .537983-1.733499C.537983-.514072 1.219427 .119552 1.996513 .119552C3.383313 .119552 4.817933-1.661768 4.817933-3.395268C4.817933-4.495143 4.196264-5.272229 3.299626-5.272229C2.677958-5.272229 2.116065-4.758157 1.888917-4.519054L2.761644-7.998007ZM2.008468-.119552C1.625903-.119552 1.207472-.406476 1.207472-1.338979C1.207472-1.733499 1.243337-1.960648 1.458531-2.797509C1.494396-2.952927 1.685679-3.718057 1.733499-3.873474C1.75741-3.969116 2.462765-5.033126 3.275716-5.033126C3.801743-5.033126 4.040847-4.507098 4.040847-3.88543C4.040847-3.311582 3.706102-1.960648 3.407223-1.338979C3.108344-.6934 2.558406-.119552 2.008468-.119552Z"
            />
            <path
              id="g1-105"
              d="M3.383313-1.709589C3.383313-1.769365 3.335492-1.817186 3.263761-1.817186C3.156164-1.817186 3.144209-1.78132 3.084433-1.578082C2.773599-.490162 2.283437-.119552 1.888917-.119552C1.745455-.119552 1.578082-.155417 1.578082-.514072C1.578082-.836862 1.721544-1.195517 1.853051-1.554172L2.689913-3.777833C2.725778-3.873474 2.809465-4.088667 2.809465-4.315816C2.809465-4.817933 2.450809-5.272229 1.865006-5.272229C.765131-5.272229 .32279-3.53873 .32279-3.443088C.32279-3.395268 .37061-3.335492 .454296-3.335492C.561893-3.335492 .573848-3.383313 .621669-3.550685C.908593-4.554919 1.362889-5.033126 1.829141-5.033126C1.936737-5.033126 2.139975-5.021171 2.139975-4.638605C2.139975-4.327771 1.984558-3.93325 1.888917-3.670237L1.052055-1.446575C.980324-1.255293 .908593-1.06401 .908593-.848817C.908593-.310834 1.279203 .119552 1.853051 .119552C2.952927 .119552 3.383313-1.625903 3.383313-1.709589ZM3.287671-7.460025C3.287671-7.639352 3.144209-7.854545 2.881196-7.854545C2.606227-7.854545 2.295392-7.591532 2.295392-7.280697C2.295392-6.981818 2.546451-6.886177 2.689913-6.886177C3.012702-6.886177 3.287671-7.197011 3.287671-7.460025Z"
            />
            <path
              id="g1-108"
              d="M3.036613-7.998007C3.048568-8.045828 3.072478-8.117559 3.072478-8.177335C3.072478-8.296887 2.952927-8.296887 2.929016-8.296887C2.917061-8.296887 2.486675-8.261021 2.271482-8.237111C2.068244-8.225156 1.888917-8.201245 1.673724-8.18929C1.3868-8.16538 1.303113-8.153425 1.303113-7.938232C1.303113-7.81868 1.422665-7.81868 1.542217-7.81868C2.15193-7.81868 2.15193-7.711083 2.15193-7.591532C2.15193-7.543711 2.15193-7.519801 2.092154-7.304608L.609714-1.374844C.573848-1.243337 .549938-1.147696 .549938-.956413C.549938-.358655 .992279 .119552 1.601993 .119552C1.996513 .119552 2.259527-.143462 2.450809-.514072C2.654047-.908593 2.82142-1.661768 2.82142-1.709589C2.82142-1.769365 2.773599-1.817186 2.701868-1.817186C2.594271-1.817186 2.582316-1.75741 2.534496-1.578082C2.319303-.753176 2.10411-.119552 1.625903-.119552C1.267248-.119552 1.267248-.502117 1.267248-.669489C1.267248-.71731 1.267248-.968369 1.350934-1.303113L3.036613-7.998007Z"
            />
            <path
              id="g1-111"
              d="M5.451557-3.287671C5.451557-4.423412 4.710336-5.272229 3.622416-5.272229C2.044334-5.272229 .490162-3.550685 .490162-1.865006C.490162-.729265 1.231382 .119552 2.319303 .119552C3.90934 .119552 5.451557-1.601993 5.451557-3.287671ZM2.331258-.119552C1.733499-.119552 1.291158-.597758 1.291158-1.43462C1.291158-1.984558 1.578082-3.203985 1.912827-3.801743C2.450809-4.722291 3.120299-5.033126 3.610461-5.033126C4.196264-5.033126 4.65056-4.554919 4.65056-3.718057C4.65056-3.239851 4.399502-1.960648 3.945205-1.231382C3.455044-.430386 2.797509-.119552 2.331258-.119552Z"
            />
            <path
              id="g1-114"
              d="M4.65056-4.889664C4.27995-4.817933 4.088667-4.554919 4.088667-4.291905C4.088667-4.004981 4.315816-3.90934 4.483188-3.90934C4.817933-3.90934 5.092902-4.196264 5.092902-4.554919C5.092902-4.937484 4.722291-5.272229 4.124533-5.272229C3.646326-5.272229 3.096389-5.057036 2.594271-4.327771C2.510585-4.961395 2.032379-5.272229 1.554172-5.272229C1.08792-5.272229 .848817-4.913574 .705355-4.65056C.502117-4.220174 .32279-3.502864 .32279-3.443088C.32279-3.395268 .37061-3.335492 .454296-3.335492C.549938-3.335492 .561893-3.347447 .633624-3.622416C.812951-4.339726 1.0401-5.033126 1.518306-5.033126C1.80523-5.033126 1.888917-4.829888 1.888917-4.483188C1.888917-4.220174 1.769365-3.753923 1.685679-3.383313L1.350934-2.092154C1.303113-1.865006 1.171606-1.327024 1.111831-1.111831C1.028144-.800996 .896638-.239103 .896638-.179328C.896638-.011955 1.028144 .119552 1.207472 .119552C1.338979 .119552 1.566127 .035866 1.637858-.203238C1.673724-.298879 2.116065-2.10411 2.187796-2.379078C2.247572-2.642092 2.319303-2.893151 2.379078-3.156164C2.426899-3.323537 2.47472-3.514819 2.510585-3.670237C2.546451-3.777833 2.86924-4.363636 3.16812-4.62665C3.311582-4.758157 3.622416-5.033126 4.112578-5.033126C4.303861-5.033126 4.495143-4.99726 4.65056-4.889664Z"
            />
            <path
              id="g1-116"
              d="M2.402989-4.805978H3.502864C3.730012-4.805978 3.849564-4.805978 3.849564-5.021171C3.849564-5.152677 3.777833-5.152677 3.53873-5.152677H2.486675L2.929016-6.898132C2.976837-7.065504 2.976837-7.089415 2.976837-7.173101C2.976837-7.364384 2.82142-7.47198 2.666002-7.47198C2.570361-7.47198 2.295392-7.436115 2.199751-7.053549L1.733499-5.152677H.609714C.37061-5.152677 .263014-5.152677 .263014-4.925529C.263014-4.805978 .3467-4.805978 .573848-4.805978H1.637858L.848817-1.649813C.753176-1.231382 .71731-1.111831 .71731-.956413C.71731-.394521 1.111831 .119552 1.78132 .119552C2.988792 .119552 3.634371-1.625903 3.634371-1.709589C3.634371-1.78132 3.58655-1.817186 3.514819-1.817186C3.490909-1.817186 3.443088-1.817186 3.419178-1.769365C3.407223-1.75741 3.395268-1.745455 3.311582-1.554172C3.060523-.956413 2.510585-.119552 1.817186-.119552C1.458531-.119552 1.43462-.418431 1.43462-.681445C1.43462-.6934 1.43462-.920548 1.470486-1.06401L2.402989-4.805978Z"
            />
            <path
              id="g1-117"
              d="M4.076712-.6934C4.23213-.02391 4.805978 .119552 5.092902 .119552C5.475467 .119552 5.762391-.131507 5.953674-.537983C6.156912-.968369 6.312329-1.673724 6.312329-1.709589C6.312329-1.769365 6.264508-1.817186 6.192777-1.817186C6.085181-1.817186 6.073225-1.75741 6.025405-1.578082C5.810212-.753176 5.595019-.119552 5.116812-.119552C4.758157-.119552 4.758157-.514072 4.758157-.669489C4.758157-.944458 4.794022-1.06401 4.913574-1.566127C4.99726-1.888917 5.080946-2.211706 5.152677-2.546451L5.642839-4.495143C5.726526-4.794022 5.726526-4.817933 5.726526-4.853798C5.726526-5.033126 5.583064-5.152677 5.403736-5.152677C5.057036-5.152677 4.97335-4.853798 4.901619-4.554919C4.782067-4.088667 4.136488-1.518306 4.052802-1.099875C4.040847-1.099875 3.574595-.119552 2.701868-.119552C2.080199-.119552 1.960648-.657534 1.960648-1.099875C1.960648-1.78132 2.295392-2.737733 2.606227-3.53873C2.749689-3.921295 2.809465-4.076712 2.809465-4.315816C2.809465-4.829888 2.438854-5.272229 1.865006-5.272229C.765131-5.272229 .32279-3.53873 .32279-3.443088C.32279-3.395268 .37061-3.335492 .454296-3.335492C.561893-3.335492 .573848-3.383313 .621669-3.550685C.908593-4.578829 1.374844-5.033126 1.829141-5.033126C1.948692-5.033126 2.139975-5.021171 2.139975-4.638605C2.139975-4.327771 2.008468-3.981071 1.829141-3.526775C1.303113-2.10411 1.243337-1.649813 1.243337-1.291158C1.243337-.071731 2.163885 .119552 2.654047 .119552C3.419178 .119552 3.837609-.406476 4.076712-.6934Z"
            />
          </defs>
          <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -66.22614)">
            <use x="70.734745" y="68.742217" xlinkHref="#g1-66" />
            <use x="80.231006" y="68.742217" xlinkHref="#g1-114" />
            <use x="85.831479" y="68.742217" xlinkHref="#g1-117" />
            <use x="92.493919" y="68.742217" xlinkHref="#g1-105" />
            <use x="96.487351" y="68.742217" xlinkHref="#g1-116" />
            <use x="100.71451" y="68.742217" xlinkHref="#g1-84" />
            <use x="109.201346" y="68.742217" xlinkHref="#g1-111" />
            <use x="114.828784" y="68.742217" xlinkHref="#g1-116" />
            <use x="119.055943" y="68.742217" xlinkHref="#g1-97" />
            <use x="125.200888" y="68.742217" xlinkHref="#g1-108" />
            <use x="132.271526" y="68.742217" xlinkHref="#g3-61" />
            <use x="144.697006" y="58.866455" xlinkHref="#g0-112" />
            <rect
              x="154.659677"
              y="58.388268"
              height=".478187"
              width="172.481855"
            />
            <use x="154.659677" y="68.742217" xlinkHref="#g1-98" />
            <use x="159.636782" y="68.742217" xlinkHref="#g1-114" />
            <use x="165.237256" y="68.742217" xlinkHref="#g1-117" />
            <use x="171.899695" y="68.742217" xlinkHref="#g1-105" />
            <use x="175.893128" y="68.742217" xlinkHref="#g1-116" />
            <use x="180.120287" y="68.742217" xlinkHref="#g1-65" />
            <use x="188.895634" y="65.288509" xlinkHref="#g2-50" />
            <use x="196.284612" y="68.742217" xlinkHref="#g3-43" />
            <use x="208.045927" y="68.742217" xlinkHref="#g1-98" />
            <use x="213.023033" y="68.742217" xlinkHref="#g1-114" />
            <use x="218.623506" y="68.742217" xlinkHref="#g1-117" />
            <use x="225.285946" y="68.742217" xlinkHref="#g1-105" />
            <use x="229.279378" y="68.742217" xlinkHref="#g1-116" />
            <use x="233.506537" y="68.742217" xlinkHref="#g1-66" />
            <use x="243.002798" y="65.288509" xlinkHref="#g2-50" />
            <use x="250.391777" y="68.742217" xlinkHref="#g3-43" />
            <use x="262.153092" y="68.742217" xlinkHref="#g1-58" />
            <use x="265.404753" y="68.742217" xlinkHref="#g1-58" />
            <use x="268.656414" y="68.742217" xlinkHref="#g1-58" />
            <use x="274.564739" y="68.742217" xlinkHref="#g3-43" />
            <use x="286.326054" y="68.742217" xlinkHref="#g1-98" />
            <use x="291.303159" y="68.742217" xlinkHref="#g1-114" />
            <use x="296.903633" y="68.742217" xlinkHref="#g1-117" />
            <use x="303.566072" y="68.742217" xlinkHref="#g1-105" />
            <use x="307.559504" y="68.742217" xlinkHref="#g1-116" />
            <use x="311.786664" y="68.742217" xlinkHref="#g1-78" />
            <use x="322.409274" y="65.288509" xlinkHref="#g2-50" />
          </g>
        </svg>
        <h3>Le calcul</h3>
        <p>
          Prenons un capteur parfait, sans bruit et supposons qu’il reçoive 49
          e- de signal :
        </p>
        <ul>
          <li>
            <p>
              <strong>Gain 1</strong>
            </p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="108.535735pt"
              height="13.772293pt"
              viewBox="-.299738 -.251206 108.535735 13.772293"
            >
              <defs>
                <path
                  id="g0-55"
                  d="M5.97818-6.501816C6.087271-6.621816 6.087271-6.643635 6.087271-7.025453H3.861817C3.610908-7.025453 2.007272-7.079998 1.854545-7.123634C1.625454-7.167271 1.614545-7.265453 1.592727-7.374543H1.08L.698182-4.603635H1.210909C1.32-5.432726 1.461818-5.574544 1.505454-5.596362C1.625454-5.661817 2.50909-5.661817 2.694545-5.661817H4.538181L3.938181-4.952726C2.890908-3.730908 2.105454-2.258181 2.105454-.643636C2.105454 .12 2.716363 .12 2.781817 .12C2.956363 .12 3.46909 .065455 3.46909-.654545V-1.156363C3.46909-1.68 3.501817-2.203636 3.578181-2.716363C3.676363-3.447272 3.905453-4.090908 4.287272-4.538181L5.97818-6.501816Z"
                />
                <path
                  id="g1-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g3-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g3-57"
                  d="M4.003635-3.46909V-3.119999C4.003635-.567273 2.86909-.065455 2.236363-.065455C2.050909-.065455 1.461818-.087273 1.167272-.458182C1.647272-.458182 1.734545-.774545 1.734545-.96C1.734545-1.298181 1.472727-1.461818 1.232727-1.461818C1.058182-1.461818 .730909-1.363636 .730909-.938182C.730909-.207273 1.32 .24 2.247272 .24C3.654544 .24 4.985453-1.243636 4.985453-3.58909C4.985453-6.523635 3.730908-7.265453 2.759999-7.265453C2.159999-7.265453 1.625454-7.069089 1.156363-6.57818C.709091-6.087271 .458182-5.629089 .458182-4.810908C.458182-3.447272 1.418181-2.378181 2.639999-2.378181C3.305454-2.378181 3.752726-2.836363 4.003635-3.46909ZM2.650908-2.62909C2.476363-2.62909 1.974545-2.62909 1.636363-3.316363C1.44-3.719999 1.44-4.265453 1.44-4.799999C1.44-5.389089 1.44-5.901817 1.66909-6.305453C1.963636-6.850907 2.378181-6.992725 2.759999-6.992725C3.261817-6.992725 3.621817-6.621816 3.807272-6.130907C3.938181-5.781817 3.981817-5.094544 3.981817-4.592726C3.981817-3.687272 3.610908-2.62909 2.650908-2.62909Z"
                />
                <path
                  id="g3-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
                <path
                  id="g2-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g2-66"
                  d="M1.745454-.850909C1.636363-.425454 1.614545-.338182 .752727-.338182C.567273-.338182 .458182-.338182 .458182-.12C.458182 0 .556363 0 .752727 0H4.647271C6.370907 0 7.65818-1.287272 7.65818-2.356363C7.65818-3.141817 7.025453-3.774544 5.967271-3.894544C7.101816-4.101817 8.24727-4.90909 8.24727-5.945453C8.24727-6.752725 7.527271-7.450907 6.21818-7.450907H2.552727C2.345454-7.450907 2.236363-7.450907 2.236363-7.232725C2.236363-7.112725 2.334545-7.112725 2.541817-7.112725C2.563636-7.112725 2.770908-7.112725 2.956363-7.090907C3.152726-7.069089 3.250908-7.05818 3.250908-6.916362C3.250908-6.872725 3.239999-6.839998 3.207272-6.709089L1.745454-.850909ZM3.392726-3.992726L4.06909-6.69818C4.167272-7.079998 4.18909-7.112725 4.658181-7.112725H6.065453C7.025453-7.112725 7.254543-6.469089 7.254543-5.989089C7.254543-5.02909 6.316362-3.992726 4.985453-3.992726H3.392726ZM2.901817-.338182C2.74909-.338182 2.727272-.338182 2.661817-.349091C2.552727-.36 2.519999-.370909 2.519999-.458182C2.519999-.490909 2.519999-.512727 2.574545-.709091L3.327272-3.752726H5.389089C6.436362-3.752726 6.643635-2.945454 6.643635-2.476363C6.643635-1.396363 5.672726-.338182 4.385453-.338182H2.901817Z"
                />
                <path
                  id="g2-82"
                  d="M4.090908-6.69818C4.156362-6.959998 4.18909-7.069089 4.396362-7.101816C4.494544-7.112725 4.843635-7.112725 5.061817-7.112725C5.836362-7.112725 7.047271-7.112725 7.047271-6.032726C7.047271-5.661817 6.872725-4.90909 6.447271-4.483635C6.163635-4.199999 5.585453-3.850908 4.603635-3.850908H3.381817L4.090908-6.69818ZM5.661817-3.70909C6.763634-3.94909 8.061816-4.712726 8.061816-5.814544C8.061816-6.752725 7.079998-7.450907 5.650908-7.450907H2.541817C2.323636-7.450907 2.225454-7.450907 2.225454-7.232725C2.225454-7.112725 2.323636-7.112725 2.530908-7.112725C2.552727-7.112725 2.759999-7.112725 2.945454-7.090907C3.141817-7.069089 3.239999-7.05818 3.239999-6.916362C3.239999-6.872725 3.22909-6.839998 3.196363-6.709089L1.734545-.850909C1.625454-.425454 1.603636-.338182 .741818-.338182C.545454-.338182 .447273-.338182 .447273-.12C.447273 0 .578182 0 .6 0C.905454 0 1.66909-.032727 1.974545-.032727S3.054545 0 3.359999 0C3.447272 0 3.578181 0 3.578181-.218182C3.578181-.338182 3.479999-.338182 3.272726-.338182C2.86909-.338182 2.563636-.338182 2.563636-.534545C2.563636-.6 2.585454-.654545 2.596363-.72L3.316363-3.610908H4.614544C5.607271-3.610908 5.803635-2.999999 5.803635-2.618181C5.803635-2.454545 5.716362-2.116363 5.650908-1.865454C5.574544-1.56 5.476362-1.156363 5.476362-.938182C5.476362 .24 6.785453 .24 6.927271 .24C7.854543 .24 8.236361-.861818 8.236361-1.014545C8.236361-1.145454 8.116361-1.145454 8.105452-1.145454C8.00727-1.145454 7.985452-1.069091 7.963634-.992727C7.690907-.185454 7.221816 0 6.970907 0C6.610907 0 6.534544-.24 6.534544-.665454C6.534544-1.003636 6.599998-1.56 6.643635-1.90909C6.665453-2.061818 6.687271-2.26909 6.687271-2.421818C6.687271-3.261817 5.956362-3.599999 5.661817-3.70909Z"
                />
                <path
                  id="g2-83"
                  d="M7.036362-7.581816C7.036362-7.614543 7.014543-7.690907 6.916362-7.690907C6.861816-7.690907 6.850907-7.679998 6.719998-7.527271L6.196362-6.905453C5.912726-7.41818 5.345453-7.690907 4.636362-7.690907C3.250908-7.690907 1.941818-6.436362 1.941818-5.116362C1.941818-4.232726 2.519999-3.730908 3.076363-3.567272L4.243635-3.261817C4.647271-3.163635 5.247271-2.999999 5.247271-2.105454C5.247271-1.123636 4.352726-.098182 3.283635-.098182C2.585454-.098182 1.374545-.338182 1.374545-1.690909C1.374545-1.952727 1.429091-2.214545 1.44-2.279999C1.450909-2.323636 1.461818-2.334545 1.461818-2.356363C1.461818-2.465454 1.385454-2.476363 1.330909-2.476363S1.254545-2.465454 1.221818-2.432727C1.178181-2.38909 .567273 .098182 .567273 .130909C.567273 .196364 .621818 .24 .687273 .24C.741818 .24 .752727 .229091 .883636 .076364L1.418181-.545454C1.887272 .087273 2.62909 .24 3.261817 .24C4.745453 .24 6.032726-1.210909 6.032726-2.563636C6.032726-3.316363 5.661817-3.687272 5.49818-3.839999C5.247271-4.090908 5.083635-4.134544 4.112726-4.385453C3.872726-4.450908 3.479999-4.559999 3.381817-4.581817C3.087272-4.679999 2.716363-4.996362 2.716363-5.574544C2.716363-6.45818 3.58909-7.385452 4.625453-7.385452C5.530908-7.385452 6.196362-6.916362 6.196362-5.694544C6.196362-5.345453 6.152726-5.149089 6.152726-5.083635C6.152726-5.072726 6.152726-4.974544 6.283635-4.974544C6.392725-4.974544 6.403635-5.007271 6.447271-5.192726L7.036362-7.581816Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -67.239435)">
                <use x="70.734745" y="68.742217" xlinkHref="#g2-82" />
                <use x="79.102182" y="68.742217" xlinkHref="#g2-83" />
                <use x="86.420361" y="68.742217" xlinkHref="#g2-66" />
                <use x="98.272581" y="68.742217" xlinkHref="#g3-61" />
                <use x="109.787705" y="68.742217" xlinkHref="#g3-52" />
                <use x="115.242269" y="68.742217" xlinkHref="#g3-57" />
                <use x="120.696834" y="68.742217" xlinkHref="#g2-61" />
                <use x="126.151399" y="59.717983" xlinkHref="#g1-112" />
                <rect
                  x="135.242323"
                  y="59.281631"
                  height=".436352"
                  width="10.909103"
                />
                <use x="135.242323" y="68.742217" xlinkHref="#g3-52" />
                <use x="140.696888" y="68.742217" xlinkHref="#g3-57" />
                <use x="149.181674" y="68.742217" xlinkHref="#g3-61" />
                <use x="160.696797" y="68.742217" xlinkHref="#g0-55" />
              </g>
            </svg>
          </li>
          <li>
            <p>
              <strong>Gain 2</strong> : le signal est multiplié par 2, ainsi que
              le bruit associé :
            </p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="153.735652pt"
              height="13.772293pt"
              viewBox="-.299738 -.251206 153.735652 13.772293"
            >
              <defs>
                <path
                  id="g0-55"
                  d="M5.97818-6.501816C6.087271-6.621816 6.087271-6.643635 6.087271-7.025453H3.861817C3.610908-7.025453 2.007272-7.079998 1.854545-7.123634C1.625454-7.167271 1.614545-7.265453 1.592727-7.374543H1.08L.698182-4.603635H1.210909C1.32-5.432726 1.461818-5.574544 1.505454-5.596362C1.625454-5.661817 2.50909-5.661817 2.694545-5.661817H4.538181L3.938181-4.952726C2.890908-3.730908 2.105454-2.258181 2.105454-.643636C2.105454 .12 2.716363 .12 2.781817 .12C2.956363 .12 3.46909 .065455 3.46909-.654545V-1.156363C3.46909-1.68 3.501817-2.203636 3.578181-2.716363C3.676363-3.447272 3.905453-4.090908 4.287272-4.538181L5.97818-6.501816Z"
                />
                <path
                  id="g1-3"
                  d="M2.999999-4.658181C3.010908-4.810908 3.010908-5.072726 2.727272-5.072726C2.552727-5.072726 2.410908-4.930908 2.443636-4.78909V-4.647271L2.596363-2.956363L1.2-3.970908C1.101818-4.025453 1.08-4.047272 1.003636-4.047272C.850909-4.047272 .709091-3.894544 .709091-3.741817C.709091-3.567272 .818182-3.523635 .927272-3.46909L2.476363-2.727272L.970909-1.996363C.796363-1.90909 .709091-1.865454 .709091-1.701818S.850909-1.396363 1.003636-1.396363C1.08-1.396363 1.101818-1.396363 1.374545-1.603636L2.596363-2.487272L2.432727-.654545C2.432727-.425454 2.62909-.370909 2.716363-.370909C2.847272-.370909 3.010908-.447273 3.010908-.654545L2.847272-2.487272L4.243635-1.472727C4.341817-1.418181 4.363635-1.396363 4.439999-1.396363C4.592726-1.396363 4.734544-1.54909 4.734544-1.701818C4.734544-1.865454 4.636362-1.919999 4.505453-1.985454C3.850908-2.312727 3.82909-2.312727 2.967272-2.716363L4.472726-3.447272C4.647271-3.534544 4.734544-3.578181 4.734544-3.741817S4.592726-4.047272 4.439999-4.047272C4.363635-4.047272 4.341817-4.047272 4.06909-3.839999L2.847272-2.956363L2.999999-4.658181Z"
                />
                <path
                  id="g1-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g3-40"
                  d="M3.610908 2.618181C3.610908 2.585454 3.610908 2.563636 3.425454 2.378181C2.061818 1.003636 1.712727-1.058182 1.712727-2.727272C1.712727-4.625453 2.127272-6.523635 3.46909-7.887271C3.610908-8.01818 3.610908-8.039998 3.610908-8.072725C3.610908-8.149089 3.567272-8.181816 3.501817-8.181816C3.392726-8.181816 2.410908-7.439998 1.767272-6.054544C1.210909-4.854544 1.08-3.643635 1.08-2.727272C1.08-1.876363 1.2-.556363 1.799999 .676363C2.454545 2.018181 3.392726 2.727272 3.501817 2.727272C3.567272 2.727272 3.610908 2.694545 3.610908 2.618181Z"
                />
                <path
                  id="g3-41"
                  d="M3.152726-2.727272C3.152726-3.578181 3.032726-4.89818 2.432727-6.130907C1.778181-7.472725 .84-8.181816 .730909-8.181816C.665454-8.181816 .621818-8.13818 .621818-8.072725C.621818-8.039998 .621818-8.01818 .829091-7.821816C1.898181-6.741816 2.519999-5.007271 2.519999-2.727272C2.519999-.861818 2.116363 1.058182 .763636 2.432727C.621818 2.563636 .621818 2.585454 .621818 2.618181C.621818 2.683636 .665454 2.727272 .730909 2.727272C.84 2.727272 1.821818 1.985454 2.465454 .6C3.021817-.6 3.152726-1.810909 3.152726-2.727272Z"
                />
                <path
                  id="g3-50"
                  d="M1.385454-.84L2.541817-1.963636C4.243635-3.46909 4.89818-4.058181 4.89818-5.149089C4.89818-6.392725 3.916363-7.265453 2.585454-7.265453C1.352727-7.265453 .545454-6.261816 .545454-5.290908C.545454-4.679999 1.090909-4.679999 1.123636-4.679999C1.309091-4.679999 1.690909-4.810908 1.690909-5.25818C1.690909-5.541817 1.494545-5.825453 1.112727-5.825453C1.025454-5.825453 1.003636-5.825453 .970909-5.814544C1.221818-6.523635 1.810909-6.927271 2.443636-6.927271C3.436363-6.927271 3.905453-6.043635 3.905453-5.149089C3.905453-4.276362 3.359999-3.414545 2.759999-2.738181L.665454-.403636C.545454-.283636 .545454-.261818 .545454 0H4.592726L4.89818-1.898181H4.625453C4.570908-1.570909 4.494544-1.090909 4.385453-.927272C4.30909-.84 3.58909-.84 3.34909-.84H1.385454Z"
                />
                <path
                  id="g3-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g3-57"
                  d="M4.003635-3.46909V-3.119999C4.003635-.567273 2.86909-.065455 2.236363-.065455C2.050909-.065455 1.461818-.087273 1.167272-.458182C1.647272-.458182 1.734545-.774545 1.734545-.96C1.734545-1.298181 1.472727-1.461818 1.232727-1.461818C1.058182-1.461818 .730909-1.363636 .730909-.938182C.730909-.207273 1.32 .24 2.247272 .24C3.654544 .24 4.985453-1.243636 4.985453-3.58909C4.985453-6.523635 3.730908-7.265453 2.759999-7.265453C2.159999-7.265453 1.625454-7.069089 1.156363-6.57818C.709091-6.087271 .458182-5.629089 .458182-4.810908C.458182-3.447272 1.418181-2.378181 2.639999-2.378181C3.305454-2.378181 3.752726-2.836363 4.003635-3.46909ZM2.650908-2.62909C2.476363-2.62909 1.974545-2.62909 1.636363-3.316363C1.44-3.719999 1.44-4.265453 1.44-4.799999C1.44-5.389089 1.44-5.901817 1.66909-6.305453C1.963636-6.850907 2.378181-6.992725 2.759999-6.992725C3.261817-6.992725 3.621817-6.621816 3.807272-6.130907C3.938181-5.781817 3.981817-5.094544 3.981817-4.592726C3.981817-3.687272 3.610908-2.62909 2.650908-2.62909Z"
                />
                <path
                  id="g3-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
                <path
                  id="g2-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g2-66"
                  d="M1.745454-.850909C1.636363-.425454 1.614545-.338182 .752727-.338182C.567273-.338182 .458182-.338182 .458182-.12C.458182 0 .556363 0 .752727 0H4.647271C6.370907 0 7.65818-1.287272 7.65818-2.356363C7.65818-3.141817 7.025453-3.774544 5.967271-3.894544C7.101816-4.101817 8.24727-4.90909 8.24727-5.945453C8.24727-6.752725 7.527271-7.450907 6.21818-7.450907H2.552727C2.345454-7.450907 2.236363-7.450907 2.236363-7.232725C2.236363-7.112725 2.334545-7.112725 2.541817-7.112725C2.563636-7.112725 2.770908-7.112725 2.956363-7.090907C3.152726-7.069089 3.250908-7.05818 3.250908-6.916362C3.250908-6.872725 3.239999-6.839998 3.207272-6.709089L1.745454-.850909ZM3.392726-3.992726L4.06909-6.69818C4.167272-7.079998 4.18909-7.112725 4.658181-7.112725H6.065453C7.025453-7.112725 7.254543-6.469089 7.254543-5.989089C7.254543-5.02909 6.316362-3.992726 4.985453-3.992726H3.392726ZM2.901817-.338182C2.74909-.338182 2.727272-.338182 2.661817-.349091C2.552727-.36 2.519999-.370909 2.519999-.458182C2.519999-.490909 2.519999-.512727 2.574545-.709091L3.327272-3.752726H5.389089C6.436362-3.752726 6.643635-2.945454 6.643635-2.476363C6.643635-1.396363 5.672726-.338182 4.385453-.338182H2.901817Z"
                />
                <path
                  id="g2-82"
                  d="M4.090908-6.69818C4.156362-6.959998 4.18909-7.069089 4.396362-7.101816C4.494544-7.112725 4.843635-7.112725 5.061817-7.112725C5.836362-7.112725 7.047271-7.112725 7.047271-6.032726C7.047271-5.661817 6.872725-4.90909 6.447271-4.483635C6.163635-4.199999 5.585453-3.850908 4.603635-3.850908H3.381817L4.090908-6.69818ZM5.661817-3.70909C6.763634-3.94909 8.061816-4.712726 8.061816-5.814544C8.061816-6.752725 7.079998-7.450907 5.650908-7.450907H2.541817C2.323636-7.450907 2.225454-7.450907 2.225454-7.232725C2.225454-7.112725 2.323636-7.112725 2.530908-7.112725C2.552727-7.112725 2.759999-7.112725 2.945454-7.090907C3.141817-7.069089 3.239999-7.05818 3.239999-6.916362C3.239999-6.872725 3.22909-6.839998 3.196363-6.709089L1.734545-.850909C1.625454-.425454 1.603636-.338182 .741818-.338182C.545454-.338182 .447273-.338182 .447273-.12C.447273 0 .578182 0 .6 0C.905454 0 1.66909-.032727 1.974545-.032727S3.054545 0 3.359999 0C3.447272 0 3.578181 0 3.578181-.218182C3.578181-.338182 3.479999-.338182 3.272726-.338182C2.86909-.338182 2.563636-.338182 2.563636-.534545C2.563636-.6 2.585454-.654545 2.596363-.72L3.316363-3.610908H4.614544C5.607271-3.610908 5.803635-2.999999 5.803635-2.618181C5.803635-2.454545 5.716362-2.116363 5.650908-1.865454C5.574544-1.56 5.476362-1.156363 5.476362-.938182C5.476362 .24 6.785453 .24 6.927271 .24C7.854543 .24 8.236361-.861818 8.236361-1.014545C8.236361-1.145454 8.116361-1.145454 8.105452-1.145454C8.00727-1.145454 7.985452-1.069091 7.963634-.992727C7.690907-.185454 7.221816 0 6.970907 0C6.610907 0 6.534544-.24 6.534544-.665454C6.534544-1.003636 6.599998-1.56 6.643635-1.90909C6.665453-2.061818 6.687271-2.26909 6.687271-2.421818C6.687271-3.261817 5.956362-3.599999 5.661817-3.70909Z"
                />
                <path
                  id="g2-83"
                  d="M7.036362-7.581816C7.036362-7.614543 7.014543-7.690907 6.916362-7.690907C6.861816-7.690907 6.850907-7.679998 6.719998-7.527271L6.196362-6.905453C5.912726-7.41818 5.345453-7.690907 4.636362-7.690907C3.250908-7.690907 1.941818-6.436362 1.941818-5.116362C1.941818-4.232726 2.519999-3.730908 3.076363-3.567272L4.243635-3.261817C4.647271-3.163635 5.247271-2.999999 5.247271-2.105454C5.247271-1.123636 4.352726-.098182 3.283635-.098182C2.585454-.098182 1.374545-.338182 1.374545-1.690909C1.374545-1.952727 1.429091-2.214545 1.44-2.279999C1.450909-2.323636 1.461818-2.334545 1.461818-2.356363C1.461818-2.465454 1.385454-2.476363 1.330909-2.476363S1.254545-2.465454 1.221818-2.432727C1.178181-2.38909 .567273 .098182 .567273 .130909C.567273 .196364 .621818 .24 .687273 .24C.741818 .24 .752727 .229091 .883636 .076364L1.418181-.545454C1.887272 .087273 2.62909 .24 3.261817 .24C4.745453 .24 6.032726-1.210909 6.032726-2.563636C6.032726-3.316363 5.661817-3.687272 5.49818-3.839999C5.247271-4.090908 5.083635-4.134544 4.112726-4.385453C3.872726-4.450908 3.479999-4.559999 3.381817-4.581817C3.087272-4.679999 2.716363-4.996362 2.716363-5.574544C2.716363-6.45818 3.58909-7.385452 4.625453-7.385452C5.530908-7.385452 6.196362-6.916362 6.196362-5.694544C6.196362-5.345453 6.152726-5.149089 6.152726-5.083635C6.152726-5.072726 6.152726-4.974544 6.283635-4.974544C6.392725-4.974544 6.403635-5.007271 6.447271-5.192726L7.036362-7.581816Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -67.239435)">
                <use x="70.734745" y="68.742217" xlinkHref="#g2-82" />
                <use x="79.102182" y="68.742217" xlinkHref="#g2-83" />
                <use x="86.420361" y="68.742217" xlinkHref="#g2-66" />
                <use x="98.272581" y="68.742217" xlinkHref="#g3-61" />
                <use x="109.787705" y="68.742217" xlinkHref="#g3-52" />
                <use x="115.242269" y="68.742217" xlinkHref="#g3-57" />
                <use x="123.121032" y="68.742217" xlinkHref="#g1-3" />
                <use x="130.999795" y="68.742217" xlinkHref="#g3-50" />
                <use x="136.454359" y="68.742217" xlinkHref="#g2-61" />
                <use x="141.908924" y="68.742217" xlinkHref="#g3-40" />
                <use x="146.151362" y="59.717983" xlinkHref="#g1-112" />
                <rect
                  x="155.242286"
                  y="59.281631"
                  height=".436352"
                  width="10.909103"
                />
                <use x="155.242286" y="68.742217" xlinkHref="#g3-52" />
                <use x="160.696851" y="68.742217" xlinkHref="#g3-57" />
                <use x="168.575587" y="68.742217" xlinkHref="#g1-3" />
                <use x="176.45435" y="68.742217" xlinkHref="#g3-50" />
                <use x="181.908915" y="68.742217" xlinkHref="#g3-41" />
                <use x="189.1816" y="68.742217" xlinkHref="#g3-61" />
                <use x="200.696724" y="68.742217" xlinkHref="#g0-55" />
              </g>
            </svg>
          </li>
          <li>
            <p>
              <strong>Gain 3</strong> : le signal est multiplié par 4, ainsi que
              le bruit associé :
            </p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="153.735652pt"
              height="13.772293pt"
              viewBox="-.299738 -.251206 153.735652 13.772293"
            >
              <defs>
                <path
                  id="g0-55"
                  d="M5.97818-6.501816C6.087271-6.621816 6.087271-6.643635 6.087271-7.025453H3.861817C3.610908-7.025453 2.007272-7.079998 1.854545-7.123634C1.625454-7.167271 1.614545-7.265453 1.592727-7.374543H1.08L.698182-4.603635H1.210909C1.32-5.432726 1.461818-5.574544 1.505454-5.596362C1.625454-5.661817 2.50909-5.661817 2.694545-5.661817H4.538181L3.938181-4.952726C2.890908-3.730908 2.105454-2.258181 2.105454-.643636C2.105454 .12 2.716363 .12 2.781817 .12C2.956363 .12 3.46909 .065455 3.46909-.654545V-1.156363C3.46909-1.68 3.501817-2.203636 3.578181-2.716363C3.676363-3.447272 3.905453-4.090908 4.287272-4.538181L5.97818-6.501816Z"
                />
                <path
                  id="g1-3"
                  d="M2.999999-4.658181C3.010908-4.810908 3.010908-5.072726 2.727272-5.072726C2.552727-5.072726 2.410908-4.930908 2.443636-4.78909V-4.647271L2.596363-2.956363L1.2-3.970908C1.101818-4.025453 1.08-4.047272 1.003636-4.047272C.850909-4.047272 .709091-3.894544 .709091-3.741817C.709091-3.567272 .818182-3.523635 .927272-3.46909L2.476363-2.727272L.970909-1.996363C.796363-1.90909 .709091-1.865454 .709091-1.701818S.850909-1.396363 1.003636-1.396363C1.08-1.396363 1.101818-1.396363 1.374545-1.603636L2.596363-2.487272L2.432727-.654545C2.432727-.425454 2.62909-.370909 2.716363-.370909C2.847272-.370909 3.010908-.447273 3.010908-.654545L2.847272-2.487272L4.243635-1.472727C4.341817-1.418181 4.363635-1.396363 4.439999-1.396363C4.592726-1.396363 4.734544-1.54909 4.734544-1.701818C4.734544-1.865454 4.636362-1.919999 4.505453-1.985454C3.850908-2.312727 3.82909-2.312727 2.967272-2.716363L4.472726-3.447272C4.647271-3.534544 4.734544-3.578181 4.734544-3.741817S4.592726-4.047272 4.439999-4.047272C4.363635-4.047272 4.341817-4.047272 4.06909-3.839999L2.847272-2.956363L2.999999-4.658181Z"
                />
                <path
                  id="g1-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g3-40"
                  d="M3.610908 2.618181C3.610908 2.585454 3.610908 2.563636 3.425454 2.378181C2.061818 1.003636 1.712727-1.058182 1.712727-2.727272C1.712727-4.625453 2.127272-6.523635 3.46909-7.887271C3.610908-8.01818 3.610908-8.039998 3.610908-8.072725C3.610908-8.149089 3.567272-8.181816 3.501817-8.181816C3.392726-8.181816 2.410908-7.439998 1.767272-6.054544C1.210909-4.854544 1.08-3.643635 1.08-2.727272C1.08-1.876363 1.2-.556363 1.799999 .676363C2.454545 2.018181 3.392726 2.727272 3.501817 2.727272C3.567272 2.727272 3.610908 2.694545 3.610908 2.618181Z"
                />
                <path
                  id="g3-41"
                  d="M3.152726-2.727272C3.152726-3.578181 3.032726-4.89818 2.432727-6.130907C1.778181-7.472725 .84-8.181816 .730909-8.181816C.665454-8.181816 .621818-8.13818 .621818-8.072725C.621818-8.039998 .621818-8.01818 .829091-7.821816C1.898181-6.741816 2.519999-5.007271 2.519999-2.727272C2.519999-.861818 2.116363 1.058182 .763636 2.432727C.621818 2.563636 .621818 2.585454 .621818 2.618181C.621818 2.683636 .665454 2.727272 .730909 2.727272C.84 2.727272 1.821818 1.985454 2.465454 .6C3.021817-.6 3.152726-1.810909 3.152726-2.727272Z"
                />
                <path
                  id="g3-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g3-57"
                  d="M4.003635-3.46909V-3.119999C4.003635-.567273 2.86909-.065455 2.236363-.065455C2.050909-.065455 1.461818-.087273 1.167272-.458182C1.647272-.458182 1.734545-.774545 1.734545-.96C1.734545-1.298181 1.472727-1.461818 1.232727-1.461818C1.058182-1.461818 .730909-1.363636 .730909-.938182C.730909-.207273 1.32 .24 2.247272 .24C3.654544 .24 4.985453-1.243636 4.985453-3.58909C4.985453-6.523635 3.730908-7.265453 2.759999-7.265453C2.159999-7.265453 1.625454-7.069089 1.156363-6.57818C.709091-6.087271 .458182-5.629089 .458182-4.810908C.458182-3.447272 1.418181-2.378181 2.639999-2.378181C3.305454-2.378181 3.752726-2.836363 4.003635-3.46909ZM2.650908-2.62909C2.476363-2.62909 1.974545-2.62909 1.636363-3.316363C1.44-3.719999 1.44-4.265453 1.44-4.799999C1.44-5.389089 1.44-5.901817 1.66909-6.305453C1.963636-6.850907 2.378181-6.992725 2.759999-6.992725C3.261817-6.992725 3.621817-6.621816 3.807272-6.130907C3.938181-5.781817 3.981817-5.094544 3.981817-4.592726C3.981817-3.687272 3.610908-2.62909 2.650908-2.62909Z"
                />
                <path
                  id="g3-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
                <path
                  id="g2-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g2-66"
                  d="M1.745454-.850909C1.636363-.425454 1.614545-.338182 .752727-.338182C.567273-.338182 .458182-.338182 .458182-.12C.458182 0 .556363 0 .752727 0H4.647271C6.370907 0 7.65818-1.287272 7.65818-2.356363C7.65818-3.141817 7.025453-3.774544 5.967271-3.894544C7.101816-4.101817 8.24727-4.90909 8.24727-5.945453C8.24727-6.752725 7.527271-7.450907 6.21818-7.450907H2.552727C2.345454-7.450907 2.236363-7.450907 2.236363-7.232725C2.236363-7.112725 2.334545-7.112725 2.541817-7.112725C2.563636-7.112725 2.770908-7.112725 2.956363-7.090907C3.152726-7.069089 3.250908-7.05818 3.250908-6.916362C3.250908-6.872725 3.239999-6.839998 3.207272-6.709089L1.745454-.850909ZM3.392726-3.992726L4.06909-6.69818C4.167272-7.079998 4.18909-7.112725 4.658181-7.112725H6.065453C7.025453-7.112725 7.254543-6.469089 7.254543-5.989089C7.254543-5.02909 6.316362-3.992726 4.985453-3.992726H3.392726ZM2.901817-.338182C2.74909-.338182 2.727272-.338182 2.661817-.349091C2.552727-.36 2.519999-.370909 2.519999-.458182C2.519999-.490909 2.519999-.512727 2.574545-.709091L3.327272-3.752726H5.389089C6.436362-3.752726 6.643635-2.945454 6.643635-2.476363C6.643635-1.396363 5.672726-.338182 4.385453-.338182H2.901817Z"
                />
                <path
                  id="g2-82"
                  d="M4.090908-6.69818C4.156362-6.959998 4.18909-7.069089 4.396362-7.101816C4.494544-7.112725 4.843635-7.112725 5.061817-7.112725C5.836362-7.112725 7.047271-7.112725 7.047271-6.032726C7.047271-5.661817 6.872725-4.90909 6.447271-4.483635C6.163635-4.199999 5.585453-3.850908 4.603635-3.850908H3.381817L4.090908-6.69818ZM5.661817-3.70909C6.763634-3.94909 8.061816-4.712726 8.061816-5.814544C8.061816-6.752725 7.079998-7.450907 5.650908-7.450907H2.541817C2.323636-7.450907 2.225454-7.450907 2.225454-7.232725C2.225454-7.112725 2.323636-7.112725 2.530908-7.112725C2.552727-7.112725 2.759999-7.112725 2.945454-7.090907C3.141817-7.069089 3.239999-7.05818 3.239999-6.916362C3.239999-6.872725 3.22909-6.839998 3.196363-6.709089L1.734545-.850909C1.625454-.425454 1.603636-.338182 .741818-.338182C.545454-.338182 .447273-.338182 .447273-.12C.447273 0 .578182 0 .6 0C.905454 0 1.66909-.032727 1.974545-.032727S3.054545 0 3.359999 0C3.447272 0 3.578181 0 3.578181-.218182C3.578181-.338182 3.479999-.338182 3.272726-.338182C2.86909-.338182 2.563636-.338182 2.563636-.534545C2.563636-.6 2.585454-.654545 2.596363-.72L3.316363-3.610908H4.614544C5.607271-3.610908 5.803635-2.999999 5.803635-2.618181C5.803635-2.454545 5.716362-2.116363 5.650908-1.865454C5.574544-1.56 5.476362-1.156363 5.476362-.938182C5.476362 .24 6.785453 .24 6.927271 .24C7.854543 .24 8.236361-.861818 8.236361-1.014545C8.236361-1.145454 8.116361-1.145454 8.105452-1.145454C8.00727-1.145454 7.985452-1.069091 7.963634-.992727C7.690907-.185454 7.221816 0 6.970907 0C6.610907 0 6.534544-.24 6.534544-.665454C6.534544-1.003636 6.599998-1.56 6.643635-1.90909C6.665453-2.061818 6.687271-2.26909 6.687271-2.421818C6.687271-3.261817 5.956362-3.599999 5.661817-3.70909Z"
                />
                <path
                  id="g2-83"
                  d="M7.036362-7.581816C7.036362-7.614543 7.014543-7.690907 6.916362-7.690907C6.861816-7.690907 6.850907-7.679998 6.719998-7.527271L6.196362-6.905453C5.912726-7.41818 5.345453-7.690907 4.636362-7.690907C3.250908-7.690907 1.941818-6.436362 1.941818-5.116362C1.941818-4.232726 2.519999-3.730908 3.076363-3.567272L4.243635-3.261817C4.647271-3.163635 5.247271-2.999999 5.247271-2.105454C5.247271-1.123636 4.352726-.098182 3.283635-.098182C2.585454-.098182 1.374545-.338182 1.374545-1.690909C1.374545-1.952727 1.429091-2.214545 1.44-2.279999C1.450909-2.323636 1.461818-2.334545 1.461818-2.356363C1.461818-2.465454 1.385454-2.476363 1.330909-2.476363S1.254545-2.465454 1.221818-2.432727C1.178181-2.38909 .567273 .098182 .567273 .130909C.567273 .196364 .621818 .24 .687273 .24C.741818 .24 .752727 .229091 .883636 .076364L1.418181-.545454C1.887272 .087273 2.62909 .24 3.261817 .24C4.745453 .24 6.032726-1.210909 6.032726-2.563636C6.032726-3.316363 5.661817-3.687272 5.49818-3.839999C5.247271-4.090908 5.083635-4.134544 4.112726-4.385453C3.872726-4.450908 3.479999-4.559999 3.381817-4.581817C3.087272-4.679999 2.716363-4.996362 2.716363-5.574544C2.716363-6.45818 3.58909-7.385452 4.625453-7.385452C5.530908-7.385452 6.196362-6.916362 6.196362-5.694544C6.196362-5.345453 6.152726-5.149089 6.152726-5.083635C6.152726-5.072726 6.152726-4.974544 6.283635-4.974544C6.392725-4.974544 6.403635-5.007271 6.447271-5.192726L7.036362-7.581816Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -67.239435)">
                <use x="70.734745" y="68.742217" xlinkHref="#g2-82" />
                <use x="79.102182" y="68.742217" xlinkHref="#g2-83" />
                <use x="86.420361" y="68.742217" xlinkHref="#g2-66" />
                <use x="98.272581" y="68.742217" xlinkHref="#g3-61" />
                <use x="109.787705" y="68.742217" xlinkHref="#g3-52" />
                <use x="115.242269" y="68.742217" xlinkHref="#g3-57" />
                <use x="123.121032" y="68.742217" xlinkHref="#g1-3" />
                <use x="130.999795" y="68.742217" xlinkHref="#g3-52" />
                <use x="136.454359" y="68.742217" xlinkHref="#g2-61" />
                <use x="141.908924" y="68.742217" xlinkHref="#g3-40" />
                <use x="146.151362" y="59.717983" xlinkHref="#g1-112" />
                <rect
                  x="155.242286"
                  y="59.281631"
                  height=".436352"
                  width="10.909103"
                />
                <use x="155.242286" y="68.742217" xlinkHref="#g3-52" />
                <use x="160.696851" y="68.742217" xlinkHref="#g3-57" />
                <use x="168.575587" y="68.742217" xlinkHref="#g1-3" />
                <use x="176.45435" y="68.742217" xlinkHref="#g3-52" />
                <use x="181.908915" y="68.742217" xlinkHref="#g3-41" />
                <use x="189.1816" y="68.742217" xlinkHref="#g3-61" />
                <use x="200.696724" y="68.742217" xlinkHref="#g0-55" />
              </g>
            </svg>
          </li>
        </ul>
        <strong>Conclusion intermédiaire :</strong> pour un capteur parfait, il
        est inutile de monter en gain ou en ISO.
        <p>
          Maintenant, introduisons du bruit de lecture fixe,{" "}
          <strong>3 e-</strong>. Rappelez-vous, pour additionner les bruits, il
          faut utiliser une somme quadratique :
        </p>
        <ul>
          <li>
            <p>
              <strong>Gain 1</strong>
            </p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="170.507978pt"
              height="22.189085pt"
              viewBox="-.299738 -.246706 170.507978 22.189085"
            >
              <defs>
                <path
                  id="g3-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g3-66"
                  d="M1.745454-.850909C1.636363-.425454 1.614545-.338182 .752727-.338182C.567273-.338182 .458182-.338182 .458182-.12C.458182 0 .556363 0 .752727 0H4.647271C6.370907 0 7.65818-1.287272 7.65818-2.356363C7.65818-3.141817 7.025453-3.774544 5.967271-3.894544C7.101816-4.101817 8.24727-4.90909 8.24727-5.945453C8.24727-6.752725 7.527271-7.450907 6.21818-7.450907H2.552727C2.345454-7.450907 2.236363-7.450907 2.236363-7.232725C2.236363-7.112725 2.334545-7.112725 2.541817-7.112725C2.563636-7.112725 2.770908-7.112725 2.956363-7.090907C3.152726-7.069089 3.250908-7.05818 3.250908-6.916362C3.250908-6.872725 3.239999-6.839998 3.207272-6.709089L1.745454-.850909ZM3.392726-3.992726L4.06909-6.69818C4.167272-7.079998 4.18909-7.112725 4.658181-7.112725H6.065453C7.025453-7.112725 7.254543-6.469089 7.254543-5.989089C7.254543-5.02909 6.316362-3.992726 4.985453-3.992726H3.392726ZM2.901817-.338182C2.74909-.338182 2.727272-.338182 2.661817-.349091C2.552727-.36 2.519999-.370909 2.519999-.458182C2.519999-.490909 2.519999-.512727 2.574545-.709091L3.327272-3.752726H5.389089C6.436362-3.752726 6.643635-2.945454 6.643635-2.476363C6.643635-1.396363 5.672726-.338182 4.385453-.338182H2.901817Z"
                />
                <path
                  id="g3-82"
                  d="M4.090908-6.69818C4.156362-6.959998 4.18909-7.069089 4.396362-7.101816C4.494544-7.112725 4.843635-7.112725 5.061817-7.112725C5.836362-7.112725 7.047271-7.112725 7.047271-6.032726C7.047271-5.661817 6.872725-4.90909 6.447271-4.483635C6.163635-4.199999 5.585453-3.850908 4.603635-3.850908H3.381817L4.090908-6.69818ZM5.661817-3.70909C6.763634-3.94909 8.061816-4.712726 8.061816-5.814544C8.061816-6.752725 7.079998-7.450907 5.650908-7.450907H2.541817C2.323636-7.450907 2.225454-7.450907 2.225454-7.232725C2.225454-7.112725 2.323636-7.112725 2.530908-7.112725C2.552727-7.112725 2.759999-7.112725 2.945454-7.090907C3.141817-7.069089 3.239999-7.05818 3.239999-6.916362C3.239999-6.872725 3.22909-6.839998 3.196363-6.709089L1.734545-.850909C1.625454-.425454 1.603636-.338182 .741818-.338182C.545454-.338182 .447273-.338182 .447273-.12C.447273 0 .578182 0 .6 0C.905454 0 1.66909-.032727 1.974545-.032727S3.054545 0 3.359999 0C3.447272 0 3.578181 0 3.578181-.218182C3.578181-.338182 3.479999-.338182 3.272726-.338182C2.86909-.338182 2.563636-.338182 2.563636-.534545C2.563636-.6 2.585454-.654545 2.596363-.72L3.316363-3.610908H4.614544C5.607271-3.610908 5.803635-2.999999 5.803635-2.618181C5.803635-2.454545 5.716362-2.116363 5.650908-1.865454C5.574544-1.56 5.476362-1.156363 5.476362-.938182C5.476362 .24 6.785453 .24 6.927271 .24C7.854543 .24 8.236361-.861818 8.236361-1.014545C8.236361-1.145454 8.116361-1.145454 8.105452-1.145454C8.00727-1.145454 7.985452-1.069091 7.963634-.992727C7.690907-.185454 7.221816 0 6.970907 0C6.610907 0 6.534544-.24 6.534544-.665454C6.534544-1.003636 6.599998-1.56 6.643635-1.90909C6.665453-2.061818 6.687271-2.26909 6.687271-2.421818C6.687271-3.261817 5.956362-3.599999 5.661817-3.70909Z"
                />
                <path
                  id="g3-83"
                  d="M7.036362-7.581816C7.036362-7.614543 7.014543-7.690907 6.916362-7.690907C6.861816-7.690907 6.850907-7.679998 6.719998-7.527271L6.196362-6.905453C5.912726-7.41818 5.345453-7.690907 4.636362-7.690907C3.250908-7.690907 1.941818-6.436362 1.941818-5.116362C1.941818-4.232726 2.519999-3.730908 3.076363-3.567272L4.243635-3.261817C4.647271-3.163635 5.247271-2.999999 5.247271-2.105454C5.247271-1.123636 4.352726-.098182 3.283635-.098182C2.585454-.098182 1.374545-.338182 1.374545-1.690909C1.374545-1.952727 1.429091-2.214545 1.44-2.279999C1.450909-2.323636 1.461818-2.334545 1.461818-2.356363C1.461818-2.465454 1.385454-2.476363 1.330909-2.476363S1.254545-2.465454 1.221818-2.432727C1.178181-2.38909 .567273 .098182 .567273 .130909C.567273 .196364 .621818 .24 .687273 .24C.741818 .24 .752727 .229091 .883636 .076364L1.418181-.545454C1.887272 .087273 2.62909 .24 3.261817 .24C4.745453 .24 6.032726-1.210909 6.032726-2.563636C6.032726-3.316363 5.661817-3.687272 5.49818-3.839999C5.247271-4.090908 5.083635-4.134544 4.112726-4.385453C3.872726-4.450908 3.479999-4.559999 3.381817-4.581817C3.087272-4.679999 2.716363-4.996362 2.716363-5.574544C2.716363-6.45818 3.58909-7.385452 4.625453-7.385452C5.530908-7.385452 6.196362-6.916362 6.196362-5.694544C6.196362-5.345453 6.152726-5.149089 6.152726-5.083635C6.152726-5.072726 6.152726-4.974544 6.283635-4.974544C6.392725-4.974544 6.403635-5.007271 6.447271-5.192726L7.036362-7.581816Z"
                />
                <path
                  id="g4-50"
                  d="M2.247572-1.625903C2.375093-1.745455 2.709838-2.008468 2.83736-2.12005C3.331507-2.574346 3.801743-3.012702 3.801743-3.737983C3.801743-4.686426 3.004732-5.300125 2.008468-5.300125C1.052055-5.300125 .422416-4.574844 .422416-3.865504C.422416-3.474969 .73325-3.419178 .844832-3.419178C1.012204-3.419178 1.259278-3.53873 1.259278-3.841594C1.259278-4.25604 .860772-4.25604 .765131-4.25604C.996264-4.837858 1.530262-5.037111 1.920797-5.037111C2.662017-5.037111 3.044583-4.407472 3.044583-3.737983C3.044583-2.909091 2.462765-2.303362 1.522291-1.338979L.518057-.302864C.422416-.215193 .422416-.199253 .422416 0H3.57061L3.801743-1.42665H3.55467C3.53076-1.267248 3.466999-.868742 3.371357-.71731C3.323537-.653549 2.717808-.653549 2.590286-.653549H1.171606L2.247572-1.625903Z"
                />
                <path
                  id="g2-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g5-43"
                  d="M4.461817-2.50909H7.505452C7.65818-2.50909 7.865452-2.50909 7.865452-2.727272S7.65818-2.945454 7.505452-2.945454H4.461817V-5.999998C4.461817-6.152726 4.461817-6.359998 4.243635-6.359998S4.025453-6.152726 4.025453-5.999998V-2.945454H.970909C.818182-2.945454 .610909-2.945454 .610909-2.727272S.818182-2.50909 .970909-2.50909H4.025453V.545454C4.025453 .698182 4.025453 .905454 4.243635 .905454S4.461817 .698182 4.461817 .545454V-2.50909Z"
                />
                <path
                  id="g5-51"
                  d="M3.163635-3.839999C4.058181-4.134544 4.690908-4.89818 4.690908-5.759998C4.690908-6.654544 3.730908-7.265453 2.683636-7.265453C1.581818-7.265453 .752727-6.610907 .752727-5.781817C.752727-5.421817 .992727-5.214544 1.309091-5.214544C1.647272-5.214544 1.865454-5.454544 1.865454-5.770907C1.865454-6.316362 1.352727-6.316362 1.189091-6.316362C1.527272-6.850907 2.247272-6.992725 2.639999-6.992725C3.087272-6.992725 3.687272-6.752725 3.687272-5.770907C3.687272-5.639998 3.665454-5.007271 3.381817-4.527271C3.054545-4.003635 2.683636-3.970908 2.410908-3.959999C2.323636-3.94909 2.061818-3.927272 1.985454-3.927272C1.898181-3.916363 1.821818-3.905453 1.821818-3.796363C1.821818-3.676363 1.898181-3.676363 2.083636-3.676363H2.563636C3.458181-3.676363 3.861817-2.934545 3.861817-1.865454C3.861817-.381818 3.10909-.065455 2.62909-.065455C2.159999-.065455 1.341818-.250909 .96-.894545C1.341818-.84 1.68-1.08 1.68-1.494545C1.68-1.887272 1.385454-2.105454 1.069091-2.105454C.807273-2.105454 .458182-1.952727 .458182-1.472727C.458182-.48 1.472727 .24 2.661817 .24C3.992726 .24 4.985453-.752727 4.985453-1.865454C4.985453-2.759999 4.298181-3.610908 3.163635-3.839999Z"
                />
                <path
                  id="g5-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g5-57"
                  d="M4.003635-3.46909V-3.119999C4.003635-.567273 2.86909-.065455 2.236363-.065455C2.050909-.065455 1.461818-.087273 1.167272-.458182C1.647272-.458182 1.734545-.774545 1.734545-.96C1.734545-1.298181 1.472727-1.461818 1.232727-1.461818C1.058182-1.461818 .730909-1.363636 .730909-.938182C.730909-.207273 1.32 .24 2.247272 .24C3.654544 .24 4.985453-1.243636 4.985453-3.58909C4.985453-6.523635 3.730908-7.265453 2.759999-7.265453C2.159999-7.265453 1.625454-7.069089 1.156363-6.57818C.709091-6.087271 .458182-5.629089 .458182-4.810908C.458182-3.447272 1.418181-2.378181 2.639999-2.378181C3.305454-2.378181 3.752726-2.836363 4.003635-3.46909ZM2.650908-2.62909C2.476363-2.62909 1.974545-2.62909 1.636363-3.316363C1.44-3.719999 1.44-4.265453 1.44-4.799999C1.44-5.389089 1.44-5.901817 1.66909-6.305453C1.963636-6.850907 2.378181-6.992725 2.759999-6.992725C3.261817-6.992725 3.621817-6.621816 3.807272-6.130907C3.938181-5.781817 3.981817-5.094544 3.981817-4.592726C3.981817-3.687272 3.610908-2.62909 2.650908-2.62909Z"
                />
                <path
                  id="g5-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
                <path
                  id="g0-46"
                  d="M2.585454-.850909C2.585454-1.32 2.203636-1.701818 1.734545-1.701818S.883636-1.32 .883636-.850909S1.265454 0 1.734545 0S2.585454-.381818 2.585454-.850909Z"
                />
                <path
                  id="g0-51"
                  d="M3.839999-3.839999C4.592726-4.06909 5.389089-4.690908 5.389089-5.629089C5.389089-6.469089 4.559999-7.145453 3.021817-7.145453C1.723636-7.145453 .872727-6.447271 .872727-5.574544C.872727-5.105453 1.210909-4.810908 1.625454-4.810908C2.116363-4.810908 2.38909-5.159999 2.38909-5.563635C2.38909-6.196362 1.799999-6.316362 1.756363-6.327271C2.138181-6.632725 2.618181-6.719998 2.956363-6.719998C3.861817-6.719998 3.894544-6.021817 3.894544-5.661817C3.894544-5.519998 3.883635-4.090908 2.74909-4.025453C2.301818-4.003635 2.279999-3.992726 2.225454-3.981817C2.116363-3.970908 2.094545-3.861817 2.094545-3.796363C2.094545-3.599999 2.203636-3.599999 2.399999-3.599999H2.879999C4.06909-3.599999 4.06909-2.530908 4.06909-1.974545C4.06909-1.461818 4.06909-.349091 2.934545-.349091C2.650908-.349091 2.083636-.392727 1.56-.72C1.919999-.818182 2.192727-1.090909 2.192727-1.538181C2.192727-2.02909 1.843636-2.367272 1.363636-2.367272C.905454-2.367272 .523636-2.072727 .523636-1.516363C.523636-.534545 1.581818 .12 2.98909 .12C4.941817 .12 5.73818-.970909 5.73818-1.963636C5.73818-2.563636 5.421817-3.501817 3.839999-3.839999Z"
                />
                <path
                  id="g0-52"
                  d="M4.854544-1.701818H5.912726V-2.214545H4.854544V-6.807271C4.854544-7.101816 4.832726-7.156362 4.527271-7.156362C4.298181-7.156362 4.287272-7.145453 4.156362-6.981816L.349091-2.214545V-1.701818H3.490908V-.512727H2.26909V0C2.683636-.032727 3.676363-.032727 4.145453-.032727C4.581817-.032727 5.530908-.032727 5.912726 0V-.512727H4.854544V-1.701818ZM3.610908-2.214545H.916363L3.610908-5.596362V-2.214545Z"
                />
                <path
                  id="g0-54"
                  d="M2.007272-3.730908V-3.938181C2.007272-5.181817 2.236363-5.650908 2.312727-5.814544C2.574545-6.327271 3.163635-6.719998 3.82909-6.719998C4.025453-6.719998 4.396362-6.687271 4.647271-6.436362C4.254544-6.381816 4.058181-6.087271 4.058181-5.759998C4.058181-5.389089 4.30909-5.083635 4.734544-5.083635S5.421817-5.367271 5.421817-5.781817C5.421817-6.45818 4.963635-7.145453 3.807272-7.145453C2.323636-7.145453 .523636-6.207271 .523636-3.458181C.523636-2.596363 .621818-1.723636 1.069091-.970909C1.581818-.141818 2.410908 .12 3.174545 .12C4.690908 .12 5.73818-.774545 5.73818-2.236363C5.73818-3.763635 4.636362-4.559999 3.316363-4.559999C3.076363-4.559999 2.465454-4.559999 2.007272-3.730908ZM3.152726-.349091C2.585454-.349091 2.290908-.774545 2.203636-.949091C2.02909-1.298181 2.02909-2.094545 2.02909-2.290908C2.02909-3.654544 2.618181-4.167272 3.218181-4.167272C4.254544-4.167272 4.254544-3.34909 4.254544-2.258181C4.254544-1.156363 4.254544-.349091 3.152726-.349091Z"
                />
                <path
                  id="g1-113"
                  d="M5.061817 17.563631L2.716363 9.381816L1.2 11.159997L1.374545 11.323633L2.116363 10.450906L4.625453 19.199995C4.985453 19.199995 4.996362 19.199995 5.083635 18.938177L11.072724 0C11.12727-.163636 11.12727-.207273 11.12727-.218182C11.12727-.338182 11.039997-.436364 10.909088-.436364C10.745452-.436364 10.701815-.294545 10.658179-.152727L5.061817 17.563631Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -66.034914)">
                <use x="70.734745" y="73.743801" xlinkHref="#g3-82" />
                <use x="79.102182" y="73.743801" xlinkHref="#g3-83" />
                <use x="86.420361" y="73.743801" xlinkHref="#g3-66" />
                <use x="98.272581" y="73.743801" xlinkHref="#g5-61" />
                <use x="109.787705" y="73.743801" xlinkHref="#g5-52" />
                <use x="115.242269" y="73.743801" xlinkHref="#g5-57" />
                <use x="120.696834" y="73.743801" xlinkHref="#g3-61" />
                <use x="126.151399" y="58.656017" xlinkHref="#g1-113" />
                <rect
                  x="137.060517"
                  y="58.219665"
                  height=".436352"
                  width="48.252479"
                />
                <use x="137.060517" y="64.719567" xlinkHref="#g2-112" />
                <rect
                  x="146.151442"
                  y="64.283215"
                  height=".436352"
                  width="10.909103"
                />
                <use x="146.151442" y="73.743801" xlinkHref="#g5-52" />
                <use x="151.606006" y="73.743801" xlinkHref="#g5-57" />
                <use x="157.060545" y="67.001704" xlinkHref="#g4-50" />
                <use x="164.217057" y="73.743801" xlinkHref="#g5-43" />
                <use x="175.126131" y="73.743801" xlinkHref="#g5-51" />
                <use x="180.580696" y="70.592289" xlinkHref="#g4-50" />
                <use x="188.343244" y="73.743801" xlinkHref="#g5-61" />
                <use x="199.858367" y="73.743801" xlinkHref="#g0-54" />
                <use x="206.131059" y="73.743801" xlinkHref="#g0-46" />
                <use x="209.615888" y="73.743801" xlinkHref="#g0-52" />
                <use x="215.888581" y="73.743801" xlinkHref="#g0-51" />
              </g>
            </svg>
          </li>
          <li>
            <p>
              <strong>Gain 2</strong>
            </p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="225.49301pt"
              height="22.189085pt"
              viewBox="-.299738 -.246706 225.49301 22.189085"
            >
              <defs>
                <path
                  id="g3-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g3-66"
                  d="M1.745454-.850909C1.636363-.425454 1.614545-.338182 .752727-.338182C.567273-.338182 .458182-.338182 .458182-.12C.458182 0 .556363 0 .752727 0H4.647271C6.370907 0 7.65818-1.287272 7.65818-2.356363C7.65818-3.141817 7.025453-3.774544 5.967271-3.894544C7.101816-4.101817 8.24727-4.90909 8.24727-5.945453C8.24727-6.752725 7.527271-7.450907 6.21818-7.450907H2.552727C2.345454-7.450907 2.236363-7.450907 2.236363-7.232725C2.236363-7.112725 2.334545-7.112725 2.541817-7.112725C2.563636-7.112725 2.770908-7.112725 2.956363-7.090907C3.152726-7.069089 3.250908-7.05818 3.250908-6.916362C3.250908-6.872725 3.239999-6.839998 3.207272-6.709089L1.745454-.850909ZM3.392726-3.992726L4.06909-6.69818C4.167272-7.079998 4.18909-7.112725 4.658181-7.112725H6.065453C7.025453-7.112725 7.254543-6.469089 7.254543-5.989089C7.254543-5.02909 6.316362-3.992726 4.985453-3.992726H3.392726ZM2.901817-.338182C2.74909-.338182 2.727272-.338182 2.661817-.349091C2.552727-.36 2.519999-.370909 2.519999-.458182C2.519999-.490909 2.519999-.512727 2.574545-.709091L3.327272-3.752726H5.389089C6.436362-3.752726 6.643635-2.945454 6.643635-2.476363C6.643635-1.396363 5.672726-.338182 4.385453-.338182H2.901817Z"
                />
                <path
                  id="g3-82"
                  d="M4.090908-6.69818C4.156362-6.959998 4.18909-7.069089 4.396362-7.101816C4.494544-7.112725 4.843635-7.112725 5.061817-7.112725C5.836362-7.112725 7.047271-7.112725 7.047271-6.032726C7.047271-5.661817 6.872725-4.90909 6.447271-4.483635C6.163635-4.199999 5.585453-3.850908 4.603635-3.850908H3.381817L4.090908-6.69818ZM5.661817-3.70909C6.763634-3.94909 8.061816-4.712726 8.061816-5.814544C8.061816-6.752725 7.079998-7.450907 5.650908-7.450907H2.541817C2.323636-7.450907 2.225454-7.450907 2.225454-7.232725C2.225454-7.112725 2.323636-7.112725 2.530908-7.112725C2.552727-7.112725 2.759999-7.112725 2.945454-7.090907C3.141817-7.069089 3.239999-7.05818 3.239999-6.916362C3.239999-6.872725 3.22909-6.839998 3.196363-6.709089L1.734545-.850909C1.625454-.425454 1.603636-.338182 .741818-.338182C.545454-.338182 .447273-.338182 .447273-.12C.447273 0 .578182 0 .6 0C.905454 0 1.66909-.032727 1.974545-.032727S3.054545 0 3.359999 0C3.447272 0 3.578181 0 3.578181-.218182C3.578181-.338182 3.479999-.338182 3.272726-.338182C2.86909-.338182 2.563636-.338182 2.563636-.534545C2.563636-.6 2.585454-.654545 2.596363-.72L3.316363-3.610908H4.614544C5.607271-3.610908 5.803635-2.999999 5.803635-2.618181C5.803635-2.454545 5.716362-2.116363 5.650908-1.865454C5.574544-1.56 5.476362-1.156363 5.476362-.938182C5.476362 .24 6.785453 .24 6.927271 .24C7.854543 .24 8.236361-.861818 8.236361-1.014545C8.236361-1.145454 8.116361-1.145454 8.105452-1.145454C8.00727-1.145454 7.985452-1.069091 7.963634-.992727C7.690907-.185454 7.221816 0 6.970907 0C6.610907 0 6.534544-.24 6.534544-.665454C6.534544-1.003636 6.599998-1.56 6.643635-1.90909C6.665453-2.061818 6.687271-2.26909 6.687271-2.421818C6.687271-3.261817 5.956362-3.599999 5.661817-3.70909Z"
                />
                <path
                  id="g3-83"
                  d="M7.036362-7.581816C7.036362-7.614543 7.014543-7.690907 6.916362-7.690907C6.861816-7.690907 6.850907-7.679998 6.719998-7.527271L6.196362-6.905453C5.912726-7.41818 5.345453-7.690907 4.636362-7.690907C3.250908-7.690907 1.941818-6.436362 1.941818-5.116362C1.941818-4.232726 2.519999-3.730908 3.076363-3.567272L4.243635-3.261817C4.647271-3.163635 5.247271-2.999999 5.247271-2.105454C5.247271-1.123636 4.352726-.098182 3.283635-.098182C2.585454-.098182 1.374545-.338182 1.374545-1.690909C1.374545-1.952727 1.429091-2.214545 1.44-2.279999C1.450909-2.323636 1.461818-2.334545 1.461818-2.356363C1.461818-2.465454 1.385454-2.476363 1.330909-2.476363S1.254545-2.465454 1.221818-2.432727C1.178181-2.38909 .567273 .098182 .567273 .130909C.567273 .196364 .621818 .24 .687273 .24C.741818 .24 .752727 .229091 .883636 .076364L1.418181-.545454C1.887272 .087273 2.62909 .24 3.261817 .24C4.745453 .24 6.032726-1.210909 6.032726-2.563636C6.032726-3.316363 5.661817-3.687272 5.49818-3.839999C5.247271-4.090908 5.083635-4.134544 4.112726-4.385453C3.872726-4.450908 3.479999-4.559999 3.381817-4.581817C3.087272-4.679999 2.716363-4.996362 2.716363-5.574544C2.716363-6.45818 3.58909-7.385452 4.625453-7.385452C5.530908-7.385452 6.196362-6.916362 6.196362-5.694544C6.196362-5.345453 6.152726-5.149089 6.152726-5.083635C6.152726-5.072726 6.152726-4.974544 6.283635-4.974544C6.392725-4.974544 6.403635-5.007271 6.447271-5.192726L7.036362-7.581816Z"
                />
                <path
                  id="g4-50"
                  d="M2.247572-1.625903C2.375093-1.745455 2.709838-2.008468 2.83736-2.12005C3.331507-2.574346 3.801743-3.012702 3.801743-3.737983C3.801743-4.686426 3.004732-5.300125 2.008468-5.300125C1.052055-5.300125 .422416-4.574844 .422416-3.865504C.422416-3.474969 .73325-3.419178 .844832-3.419178C1.012204-3.419178 1.259278-3.53873 1.259278-3.841594C1.259278-4.25604 .860772-4.25604 .765131-4.25604C.996264-4.837858 1.530262-5.037111 1.920797-5.037111C2.662017-5.037111 3.044583-4.407472 3.044583-3.737983C3.044583-2.909091 2.462765-2.303362 1.522291-1.338979L.518057-.302864C.422416-.215193 .422416-.199253 .422416 0H3.57061L3.801743-1.42665H3.55467C3.53076-1.267248 3.466999-.868742 3.371357-.71731C3.323537-.653549 2.717808-.653549 2.590286-.653549H1.171606L2.247572-1.625903Z"
                />
                <path
                  id="g2-3"
                  d="M2.999999-4.658181C3.010908-4.810908 3.010908-5.072726 2.727272-5.072726C2.552727-5.072726 2.410908-4.930908 2.443636-4.78909V-4.647271L2.596363-2.956363L1.2-3.970908C1.101818-4.025453 1.08-4.047272 1.003636-4.047272C.850909-4.047272 .709091-3.894544 .709091-3.741817C.709091-3.567272 .818182-3.523635 .927272-3.46909L2.476363-2.727272L.970909-1.996363C.796363-1.90909 .709091-1.865454 .709091-1.701818S.850909-1.396363 1.003636-1.396363C1.08-1.396363 1.101818-1.396363 1.374545-1.603636L2.596363-2.487272L2.432727-.654545C2.432727-.425454 2.62909-.370909 2.716363-.370909C2.847272-.370909 3.010908-.447273 3.010908-.654545L2.847272-2.487272L4.243635-1.472727C4.341817-1.418181 4.363635-1.396363 4.439999-1.396363C4.592726-1.396363 4.734544-1.54909 4.734544-1.701818C4.734544-1.865454 4.636362-1.919999 4.505453-1.985454C3.850908-2.312727 3.82909-2.312727 2.967272-2.716363L4.472726-3.447272C4.647271-3.534544 4.734544-3.578181 4.734544-3.741817S4.592726-4.047272 4.439999-4.047272C4.363635-4.047272 4.341817-4.047272 4.06909-3.839999L2.847272-2.956363L2.999999-4.658181Z"
                />
                <path
                  id="g2-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g5-40"
                  d="M3.610908 2.618181C3.610908 2.585454 3.610908 2.563636 3.425454 2.378181C2.061818 1.003636 1.712727-1.058182 1.712727-2.727272C1.712727-4.625453 2.127272-6.523635 3.46909-7.887271C3.610908-8.01818 3.610908-8.039998 3.610908-8.072725C3.610908-8.149089 3.567272-8.181816 3.501817-8.181816C3.392726-8.181816 2.410908-7.439998 1.767272-6.054544C1.210909-4.854544 1.08-3.643635 1.08-2.727272C1.08-1.876363 1.2-.556363 1.799999 .676363C2.454545 2.018181 3.392726 2.727272 3.501817 2.727272C3.567272 2.727272 3.610908 2.694545 3.610908 2.618181Z"
                />
                <path
                  id="g5-41"
                  d="M3.152726-2.727272C3.152726-3.578181 3.032726-4.89818 2.432727-6.130907C1.778181-7.472725 .84-8.181816 .730909-8.181816C.665454-8.181816 .621818-8.13818 .621818-8.072725C.621818-8.039998 .621818-8.01818 .829091-7.821816C1.898181-6.741816 2.519999-5.007271 2.519999-2.727272C2.519999-.861818 2.116363 1.058182 .763636 2.432727C.621818 2.563636 .621818 2.585454 .621818 2.618181C.621818 2.683636 .665454 2.727272 .730909 2.727272C.84 2.727272 1.821818 1.985454 2.465454 .6C3.021817-.6 3.152726-1.810909 3.152726-2.727272Z"
                />
                <path
                  id="g5-43"
                  d="M4.461817-2.50909H7.505452C7.65818-2.50909 7.865452-2.50909 7.865452-2.727272S7.65818-2.945454 7.505452-2.945454H4.461817V-5.999998C4.461817-6.152726 4.461817-6.359998 4.243635-6.359998S4.025453-6.152726 4.025453-5.999998V-2.945454H.970909C.818182-2.945454 .610909-2.945454 .610909-2.727272S.818182-2.50909 .970909-2.50909H4.025453V.545454C4.025453 .698182 4.025453 .905454 4.243635 .905454S4.461817 .698182 4.461817 .545454V-2.50909Z"
                />
                <path
                  id="g5-50"
                  d="M1.385454-.84L2.541817-1.963636C4.243635-3.46909 4.89818-4.058181 4.89818-5.149089C4.89818-6.392725 3.916363-7.265453 2.585454-7.265453C1.352727-7.265453 .545454-6.261816 .545454-5.290908C.545454-4.679999 1.090909-4.679999 1.123636-4.679999C1.309091-4.679999 1.690909-4.810908 1.690909-5.25818C1.690909-5.541817 1.494545-5.825453 1.112727-5.825453C1.025454-5.825453 1.003636-5.825453 .970909-5.814544C1.221818-6.523635 1.810909-6.927271 2.443636-6.927271C3.436363-6.927271 3.905453-6.043635 3.905453-5.149089C3.905453-4.276362 3.359999-3.414545 2.759999-2.738181L.665454-.403636C.545454-.283636 .545454-.261818 .545454 0H4.592726L4.89818-1.898181H4.625453C4.570908-1.570909 4.494544-1.090909 4.385453-.927272C4.30909-.84 3.58909-.84 3.34909-.84H1.385454Z"
                />
                <path
                  id="g5-51"
                  d="M3.163635-3.839999C4.058181-4.134544 4.690908-4.89818 4.690908-5.759998C4.690908-6.654544 3.730908-7.265453 2.683636-7.265453C1.581818-7.265453 .752727-6.610907 .752727-5.781817C.752727-5.421817 .992727-5.214544 1.309091-5.214544C1.647272-5.214544 1.865454-5.454544 1.865454-5.770907C1.865454-6.316362 1.352727-6.316362 1.189091-6.316362C1.527272-6.850907 2.247272-6.992725 2.639999-6.992725C3.087272-6.992725 3.687272-6.752725 3.687272-5.770907C3.687272-5.639998 3.665454-5.007271 3.381817-4.527271C3.054545-4.003635 2.683636-3.970908 2.410908-3.959999C2.323636-3.94909 2.061818-3.927272 1.985454-3.927272C1.898181-3.916363 1.821818-3.905453 1.821818-3.796363C1.821818-3.676363 1.898181-3.676363 2.083636-3.676363H2.563636C3.458181-3.676363 3.861817-2.934545 3.861817-1.865454C3.861817-.381818 3.10909-.065455 2.62909-.065455C2.159999-.065455 1.341818-.250909 .96-.894545C1.341818-.84 1.68-1.08 1.68-1.494545C1.68-1.887272 1.385454-2.105454 1.069091-2.105454C.807273-2.105454 .458182-1.952727 .458182-1.472727C.458182-.48 1.472727 .24 2.661817 .24C3.992726 .24 4.985453-.752727 4.985453-1.865454C4.985453-2.759999 4.298181-3.610908 3.163635-3.839999Z"
                />
                <path
                  id="g5-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g5-57"
                  d="M4.003635-3.46909V-3.119999C4.003635-.567273 2.86909-.065455 2.236363-.065455C2.050909-.065455 1.461818-.087273 1.167272-.458182C1.647272-.458182 1.734545-.774545 1.734545-.96C1.734545-1.298181 1.472727-1.461818 1.232727-1.461818C1.058182-1.461818 .730909-1.363636 .730909-.938182C.730909-.207273 1.32 .24 2.247272 .24C3.654544 .24 4.985453-1.243636 4.985453-3.58909C4.985453-6.523635 3.730908-7.265453 2.759999-7.265453C2.159999-7.265453 1.625454-7.069089 1.156363-6.57818C.709091-6.087271 .458182-5.629089 .458182-4.810908C.458182-3.447272 1.418181-2.378181 2.639999-2.378181C3.305454-2.378181 3.752726-2.836363 4.003635-3.46909ZM2.650908-2.62909C2.476363-2.62909 1.974545-2.62909 1.636363-3.316363C1.44-3.719999 1.44-4.265453 1.44-4.799999C1.44-5.389089 1.44-5.901817 1.66909-6.305453C1.963636-6.850907 2.378181-6.992725 2.759999-6.992725C3.261817-6.992725 3.621817-6.621816 3.807272-6.130907C3.938181-5.781817 3.981817-5.094544 3.981817-4.592726C3.981817-3.687272 3.610908-2.62909 2.650908-2.62909Z"
                />
                <path
                  id="g5-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
                <path
                  id="g0-46"
                  d="M2.585454-.850909C2.585454-1.32 2.203636-1.701818 1.734545-1.701818S.883636-1.32 .883636-.850909S1.265454 0 1.734545 0S2.585454-.381818 2.585454-.850909Z"
                />
                <path
                  id="g0-52"
                  d="M4.854544-1.701818H5.912726V-2.214545H4.854544V-6.807271C4.854544-7.101816 4.832726-7.156362 4.527271-7.156362C4.298181-7.156362 4.287272-7.145453 4.156362-6.981816L.349091-2.214545V-1.701818H3.490908V-.512727H2.26909V0C2.683636-.032727 3.676363-.032727 4.145453-.032727C4.581817-.032727 5.530908-.032727 5.912726 0V-.512727H4.854544V-1.701818ZM3.610908-2.214545H.916363L3.610908-5.596362V-2.214545Z"
                />
                <path
                  id="g0-54"
                  d="M2.007272-3.730908V-3.938181C2.007272-5.181817 2.236363-5.650908 2.312727-5.814544C2.574545-6.327271 3.163635-6.719998 3.82909-6.719998C4.025453-6.719998 4.396362-6.687271 4.647271-6.436362C4.254544-6.381816 4.058181-6.087271 4.058181-5.759998C4.058181-5.389089 4.30909-5.083635 4.734544-5.083635S5.421817-5.367271 5.421817-5.781817C5.421817-6.45818 4.963635-7.145453 3.807272-7.145453C2.323636-7.145453 .523636-6.207271 .523636-3.458181C.523636-2.596363 .621818-1.723636 1.069091-.970909C1.581818-.141818 2.410908 .12 3.174545 .12C4.690908 .12 5.73818-.774545 5.73818-2.236363C5.73818-3.763635 4.636362-4.559999 3.316363-4.559999C3.076363-4.559999 2.465454-4.559999 2.007272-3.730908ZM3.152726-.349091C2.585454-.349091 2.290908-.774545 2.203636-.949091C2.02909-1.298181 2.02909-2.094545 2.02909-2.290908C2.02909-3.654544 2.618181-4.167272 3.218181-4.167272C4.254544-4.167272 4.254544-3.34909 4.254544-2.258181C4.254544-1.156363 4.254544-.349091 3.152726-.349091Z"
                />
                <path
                  id="g0-56"
                  d="M2.170908-5.225453C1.996363-5.323635 1.690909-5.509089 1.690909-5.879998C1.690909-6.643635 2.672727-6.719998 3.10909-6.719998C4.025453-6.719998 4.570908-6.272726 4.570908-5.530908C4.570908-4.996362 4.287272-4.625453 3.807272-4.30909L2.170908-5.225453ZM4.287272-4.036363C5.050908-4.42909 5.389089-4.90909 5.389089-5.541817C5.389089-6.654544 4.396362-7.145453 3.152726-7.145453C1.636363-7.145453 .872727-6.283635 .872727-5.236362C.872727-4.756362 1.069091-4.06909 1.919999-3.58909C1.003636-3.185454 .523636-2.563636 .523636-1.756363C.523636-.436364 1.701818 .12 3.10909 .12C4.854544 .12 5.73818-.84 5.73818-2.039999C5.73818-3.218181 4.821817-3.730908 4.287272-4.036363ZM2.421818-3.294545C2.803636-3.098181 3.316363-2.803636 3.916363-2.465454C4.396362-2.192727 4.799999-1.963636 4.799999-1.44C4.799999-.523636 3.774544-.349091 3.152726-.349091C2.083636-.349091 1.461818-.905454 1.461818-1.756363S2.039999-3.076363 2.421818-3.294545Z"
                />
                <path
                  id="g1-113"
                  d="M5.061817 17.563631L2.716363 9.381816L1.2 11.159997L1.374545 11.323633L2.116363 10.450906L4.625453 19.199995C4.985453 19.199995 4.996362 19.199995 5.083635 18.938177L11.072724 0C11.12727-.163636 11.12727-.207273 11.12727-.218182C11.12727-.338182 11.039997-.436364 10.909088-.436364C10.745452-.436364 10.701815-.294545 10.658179-.152727L5.061817 17.563631Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -66.034914)">
                <use x="70.734745" y="72.113683" xlinkHref="#g3-82" />
                <use x="79.102182" y="72.113683" xlinkHref="#g3-83" />
                <use x="86.420361" y="72.113683" xlinkHref="#g3-66" />
                <use x="98.272581" y="72.113683" xlinkHref="#g5-61" />
                <use x="109.787705" y="72.113683" xlinkHref="#g5-40" />
                <use x="114.030143" y="72.113683" xlinkHref="#g5-52" />
                <use x="119.484708" y="72.113683" xlinkHref="#g5-57" />
                <use x="127.36347" y="72.113683" xlinkHref="#g2-3" />
                <use x="135.242233" y="72.113683" xlinkHref="#g5-50" />
                <use x="140.696797" y="72.113683" xlinkHref="#g5-41" />
                <use x="144.939236" y="72.113683" xlinkHref="#g3-61" />
                <use x="150.3938" y="58.656017" xlinkHref="#g1-113" />
                <rect
                  x="161.302919"
                  y="58.219665"
                  height=".436352"
                  width="72.494853"
                />
                <use x="161.302919" y="72.113683" xlinkHref="#g5-40" />
                <use x="165.545357" y="63.089449" xlinkHref="#g2-112" />
                <rect
                  x="174.636281"
                  y="62.653097"
                  height=".436352"
                  width="10.909103"
                />
                <use x="174.636281" y="72.113683" xlinkHref="#g5-52" />
                <use x="180.090846" y="72.113683" xlinkHref="#g5-57" />
                <use x="187.969582" y="72.113683" xlinkHref="#g2-3" />
                <use x="195.848344" y="72.113683" xlinkHref="#g5-50" />
                <use x="201.302909" y="72.113683" xlinkHref="#g5-41" />
                <use x="205.545347" y="68.962171" xlinkHref="#g4-50" />
                <use x="212.70186" y="72.113683" xlinkHref="#g5-43" />
                <use x="223.610934" y="72.113683" xlinkHref="#g5-51" />
                <use x="229.065499" y="68.962171" xlinkHref="#g4-50" />
                <use x="236.828019" y="72.113683" xlinkHref="#g5-61" />
                <use x="248.343142" y="72.113683" xlinkHref="#g0-54" />
                <use x="254.615834" y="72.113683" xlinkHref="#g0-46" />
                <use x="258.100663" y="72.113683" xlinkHref="#g0-56" />
                <use x="264.373355" y="72.113683" xlinkHref="#g0-52" />
              </g>
            </svg>
          </li>
          <li>
            <p>
              <strong>Gain 3</strong>
            </p>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="225.295774pt"
              height="22.189085pt"
              viewBox="-.299738 -.246706 225.295774 22.189085"
            >
              <defs>
                <path
                  id="g3-61"
                  d="M4.78909-7.767271C4.843635-7.909089 4.843635-7.952725 4.843635-7.963634C4.843635-8.083634 4.745453-8.181816 4.625453-8.181816C4.54909-8.181816 4.472726-8.149089 4.439999-8.083634L.654545 2.312727C.6 2.454545 .6 2.498181 .6 2.50909C.6 2.62909 .698182 2.727272 .818182 2.727272C.96 2.727272 .992727 2.650908 1.058182 2.465454L4.78909-7.767271Z"
                />
                <path
                  id="g3-66"
                  d="M1.745454-.850909C1.636363-.425454 1.614545-.338182 .752727-.338182C.567273-.338182 .458182-.338182 .458182-.12C.458182 0 .556363 0 .752727 0H4.647271C6.370907 0 7.65818-1.287272 7.65818-2.356363C7.65818-3.141817 7.025453-3.774544 5.967271-3.894544C7.101816-4.101817 8.24727-4.90909 8.24727-5.945453C8.24727-6.752725 7.527271-7.450907 6.21818-7.450907H2.552727C2.345454-7.450907 2.236363-7.450907 2.236363-7.232725C2.236363-7.112725 2.334545-7.112725 2.541817-7.112725C2.563636-7.112725 2.770908-7.112725 2.956363-7.090907C3.152726-7.069089 3.250908-7.05818 3.250908-6.916362C3.250908-6.872725 3.239999-6.839998 3.207272-6.709089L1.745454-.850909ZM3.392726-3.992726L4.06909-6.69818C4.167272-7.079998 4.18909-7.112725 4.658181-7.112725H6.065453C7.025453-7.112725 7.254543-6.469089 7.254543-5.989089C7.254543-5.02909 6.316362-3.992726 4.985453-3.992726H3.392726ZM2.901817-.338182C2.74909-.338182 2.727272-.338182 2.661817-.349091C2.552727-.36 2.519999-.370909 2.519999-.458182C2.519999-.490909 2.519999-.512727 2.574545-.709091L3.327272-3.752726H5.389089C6.436362-3.752726 6.643635-2.945454 6.643635-2.476363C6.643635-1.396363 5.672726-.338182 4.385453-.338182H2.901817Z"
                />
                <path
                  id="g3-82"
                  d="M4.090908-6.69818C4.156362-6.959998 4.18909-7.069089 4.396362-7.101816C4.494544-7.112725 4.843635-7.112725 5.061817-7.112725C5.836362-7.112725 7.047271-7.112725 7.047271-6.032726C7.047271-5.661817 6.872725-4.90909 6.447271-4.483635C6.163635-4.199999 5.585453-3.850908 4.603635-3.850908H3.381817L4.090908-6.69818ZM5.661817-3.70909C6.763634-3.94909 8.061816-4.712726 8.061816-5.814544C8.061816-6.752725 7.079998-7.450907 5.650908-7.450907H2.541817C2.323636-7.450907 2.225454-7.450907 2.225454-7.232725C2.225454-7.112725 2.323636-7.112725 2.530908-7.112725C2.552727-7.112725 2.759999-7.112725 2.945454-7.090907C3.141817-7.069089 3.239999-7.05818 3.239999-6.916362C3.239999-6.872725 3.22909-6.839998 3.196363-6.709089L1.734545-.850909C1.625454-.425454 1.603636-.338182 .741818-.338182C.545454-.338182 .447273-.338182 .447273-.12C.447273 0 .578182 0 .6 0C.905454 0 1.66909-.032727 1.974545-.032727S3.054545 0 3.359999 0C3.447272 0 3.578181 0 3.578181-.218182C3.578181-.338182 3.479999-.338182 3.272726-.338182C2.86909-.338182 2.563636-.338182 2.563636-.534545C2.563636-.6 2.585454-.654545 2.596363-.72L3.316363-3.610908H4.614544C5.607271-3.610908 5.803635-2.999999 5.803635-2.618181C5.803635-2.454545 5.716362-2.116363 5.650908-1.865454C5.574544-1.56 5.476362-1.156363 5.476362-.938182C5.476362 .24 6.785453 .24 6.927271 .24C7.854543 .24 8.236361-.861818 8.236361-1.014545C8.236361-1.145454 8.116361-1.145454 8.105452-1.145454C8.00727-1.145454 7.985452-1.069091 7.963634-.992727C7.690907-.185454 7.221816 0 6.970907 0C6.610907 0 6.534544-.24 6.534544-.665454C6.534544-1.003636 6.599998-1.56 6.643635-1.90909C6.665453-2.061818 6.687271-2.26909 6.687271-2.421818C6.687271-3.261817 5.956362-3.599999 5.661817-3.70909Z"
                />
                <path
                  id="g3-83"
                  d="M7.036362-7.581816C7.036362-7.614543 7.014543-7.690907 6.916362-7.690907C6.861816-7.690907 6.850907-7.679998 6.719998-7.527271L6.196362-6.905453C5.912726-7.41818 5.345453-7.690907 4.636362-7.690907C3.250908-7.690907 1.941818-6.436362 1.941818-5.116362C1.941818-4.232726 2.519999-3.730908 3.076363-3.567272L4.243635-3.261817C4.647271-3.163635 5.247271-2.999999 5.247271-2.105454C5.247271-1.123636 4.352726-.098182 3.283635-.098182C2.585454-.098182 1.374545-.338182 1.374545-1.690909C1.374545-1.952727 1.429091-2.214545 1.44-2.279999C1.450909-2.323636 1.461818-2.334545 1.461818-2.356363C1.461818-2.465454 1.385454-2.476363 1.330909-2.476363S1.254545-2.465454 1.221818-2.432727C1.178181-2.38909 .567273 .098182 .567273 .130909C.567273 .196364 .621818 .24 .687273 .24C.741818 .24 .752727 .229091 .883636 .076364L1.418181-.545454C1.887272 .087273 2.62909 .24 3.261817 .24C4.745453 .24 6.032726-1.210909 6.032726-2.563636C6.032726-3.316363 5.661817-3.687272 5.49818-3.839999C5.247271-4.090908 5.083635-4.134544 4.112726-4.385453C3.872726-4.450908 3.479999-4.559999 3.381817-4.581817C3.087272-4.679999 2.716363-4.996362 2.716363-5.574544C2.716363-6.45818 3.58909-7.385452 4.625453-7.385452C5.530908-7.385452 6.196362-6.916362 6.196362-5.694544C6.196362-5.345453 6.152726-5.149089 6.152726-5.083635C6.152726-5.072726 6.152726-4.974544 6.283635-4.974544C6.392725-4.974544 6.403635-5.007271 6.447271-5.192726L7.036362-7.581816Z"
                />
                <path
                  id="g4-50"
                  d="M2.247572-1.625903C2.375093-1.745455 2.709838-2.008468 2.83736-2.12005C3.331507-2.574346 3.801743-3.012702 3.801743-3.737983C3.801743-4.686426 3.004732-5.300125 2.008468-5.300125C1.052055-5.300125 .422416-4.574844 .422416-3.865504C.422416-3.474969 .73325-3.419178 .844832-3.419178C1.012204-3.419178 1.259278-3.53873 1.259278-3.841594C1.259278-4.25604 .860772-4.25604 .765131-4.25604C.996264-4.837858 1.530262-5.037111 1.920797-5.037111C2.662017-5.037111 3.044583-4.407472 3.044583-3.737983C3.044583-2.909091 2.462765-2.303362 1.522291-1.338979L.518057-.302864C.422416-.215193 .422416-.199253 .422416 0H3.57061L3.801743-1.42665H3.55467C3.53076-1.267248 3.466999-.868742 3.371357-.71731C3.323537-.653549 2.717808-.653549 2.590286-.653549H1.171606L2.247572-1.625903Z"
                />
                <path
                  id="g2-3"
                  d="M2.999999-4.658181C3.010908-4.810908 3.010908-5.072726 2.727272-5.072726C2.552727-5.072726 2.410908-4.930908 2.443636-4.78909V-4.647271L2.596363-2.956363L1.2-3.970908C1.101818-4.025453 1.08-4.047272 1.003636-4.047272C.850909-4.047272 .709091-3.894544 .709091-3.741817C.709091-3.567272 .818182-3.523635 .927272-3.46909L2.476363-2.727272L.970909-1.996363C.796363-1.90909 .709091-1.865454 .709091-1.701818S.850909-1.396363 1.003636-1.396363C1.08-1.396363 1.101818-1.396363 1.374545-1.603636L2.596363-2.487272L2.432727-.654545C2.432727-.425454 2.62909-.370909 2.716363-.370909C2.847272-.370909 3.010908-.447273 3.010908-.654545L2.847272-2.487272L4.243635-1.472727C4.341817-1.418181 4.363635-1.396363 4.439999-1.396363C4.592726-1.396363 4.734544-1.54909 4.734544-1.701818C4.734544-1.865454 4.636362-1.919999 4.505453-1.985454C3.850908-2.312727 3.82909-2.312727 2.967272-2.716363L4.472726-3.447272C4.647271-3.534544 4.734544-3.578181 4.734544-3.741817S4.592726-4.047272 4.439999-4.047272C4.363635-4.047272 4.341817-4.047272 4.06909-3.839999L2.847272-2.956363L2.999999-4.658181Z"
                />
                <path
                  id="g2-112"
                  d="M4.243635 9.32727L2.323636 5.083635C2.247272 4.90909 2.192727 4.90909 2.159999 4.90909C2.14909 4.90909 2.094545 4.90909 1.974545 4.996362L.938182 5.781817C.796363 5.890907 .796363 5.923635 .796363 5.956362C.796363 6.010907 .829091 6.076362 .905454 6.076362C.970909 6.076362 1.156363 5.923635 1.276363 5.836362C1.341818 5.781817 1.505454 5.661817 1.625454 5.574544L3.774544 10.298179C3.850908 10.472724 3.905453 10.472724 4.003635 10.472724C4.167272 10.472724 4.199999 10.40727 4.276362 10.254543L9.229088 0C9.305452-.152727 9.305452-.196364 9.305452-.218182C9.305452-.327273 9.218179-.436364 9.08727-.436364C8.999997-.436364 8.923634-.381818 8.836361-.207273L4.243635 9.32727Z"
                />
                <path
                  id="g5-40"
                  d="M3.610908 2.618181C3.610908 2.585454 3.610908 2.563636 3.425454 2.378181C2.061818 1.003636 1.712727-1.058182 1.712727-2.727272C1.712727-4.625453 2.127272-6.523635 3.46909-7.887271C3.610908-8.01818 3.610908-8.039998 3.610908-8.072725C3.610908-8.149089 3.567272-8.181816 3.501817-8.181816C3.392726-8.181816 2.410908-7.439998 1.767272-6.054544C1.210909-4.854544 1.08-3.643635 1.08-2.727272C1.08-1.876363 1.2-.556363 1.799999 .676363C2.454545 2.018181 3.392726 2.727272 3.501817 2.727272C3.567272 2.727272 3.610908 2.694545 3.610908 2.618181Z"
                />
                <path
                  id="g5-41"
                  d="M3.152726-2.727272C3.152726-3.578181 3.032726-4.89818 2.432727-6.130907C1.778181-7.472725 .84-8.181816 .730909-8.181816C.665454-8.181816 .621818-8.13818 .621818-8.072725C.621818-8.039998 .621818-8.01818 .829091-7.821816C1.898181-6.741816 2.519999-5.007271 2.519999-2.727272C2.519999-.861818 2.116363 1.058182 .763636 2.432727C.621818 2.563636 .621818 2.585454 .621818 2.618181C.621818 2.683636 .665454 2.727272 .730909 2.727272C.84 2.727272 1.821818 1.985454 2.465454 .6C3.021817-.6 3.152726-1.810909 3.152726-2.727272Z"
                />
                <path
                  id="g5-43"
                  d="M4.461817-2.50909H7.505452C7.65818-2.50909 7.865452-2.50909 7.865452-2.727272S7.65818-2.945454 7.505452-2.945454H4.461817V-5.999998C4.461817-6.152726 4.461817-6.359998 4.243635-6.359998S4.025453-6.152726 4.025453-5.999998V-2.945454H.970909C.818182-2.945454 .610909-2.945454 .610909-2.727272S.818182-2.50909 .970909-2.50909H4.025453V.545454C4.025453 .698182 4.025453 .905454 4.243635 .905454S4.461817 .698182 4.461817 .545454V-2.50909Z"
                />
                <path
                  id="g5-51"
                  d="M3.163635-3.839999C4.058181-4.134544 4.690908-4.89818 4.690908-5.759998C4.690908-6.654544 3.730908-7.265453 2.683636-7.265453C1.581818-7.265453 .752727-6.610907 .752727-5.781817C.752727-5.421817 .992727-5.214544 1.309091-5.214544C1.647272-5.214544 1.865454-5.454544 1.865454-5.770907C1.865454-6.316362 1.352727-6.316362 1.189091-6.316362C1.527272-6.850907 2.247272-6.992725 2.639999-6.992725C3.087272-6.992725 3.687272-6.752725 3.687272-5.770907C3.687272-5.639998 3.665454-5.007271 3.381817-4.527271C3.054545-4.003635 2.683636-3.970908 2.410908-3.959999C2.323636-3.94909 2.061818-3.927272 1.985454-3.927272C1.898181-3.916363 1.821818-3.905453 1.821818-3.796363C1.821818-3.676363 1.898181-3.676363 2.083636-3.676363H2.563636C3.458181-3.676363 3.861817-2.934545 3.861817-1.865454C3.861817-.381818 3.10909-.065455 2.62909-.065455C2.159999-.065455 1.341818-.250909 .96-.894545C1.341818-.84 1.68-1.08 1.68-1.494545C1.68-1.887272 1.385454-2.105454 1.069091-2.105454C.807273-2.105454 .458182-1.952727 .458182-1.472727C.458182-.48 1.472727 .24 2.661817 .24C3.992726 .24 4.985453-.752727 4.985453-1.865454C4.985453-2.759999 4.298181-3.610908 3.163635-3.839999Z"
                />
                <path
                  id="g5-52"
                  d="M3.207272-1.799999V-.850909C3.207272-.458182 3.185454-.338182 2.378181-.338182H2.14909V0C2.596363-.032727 3.163635-.032727 3.621817-.032727S4.658181-.032727 5.105453 0V-.338182H4.876362C4.06909-.338182 4.047272-.458182 4.047272-.850909V-1.799999H5.13818V-2.138181H4.047272V-7.101816C4.047272-7.319998 4.047272-7.385452 3.872726-7.385452C3.774544-7.385452 3.741817-7.385452 3.654544-7.254543L.305454-2.138181V-1.799999H3.207272ZM3.272726-2.138181H.610909L3.272726-6.207271V-2.138181Z"
                />
                <path
                  id="g5-57"
                  d="M4.003635-3.46909V-3.119999C4.003635-.567273 2.86909-.065455 2.236363-.065455C2.050909-.065455 1.461818-.087273 1.167272-.458182C1.647272-.458182 1.734545-.774545 1.734545-.96C1.734545-1.298181 1.472727-1.461818 1.232727-1.461818C1.058182-1.461818 .730909-1.363636 .730909-.938182C.730909-.207273 1.32 .24 2.247272 .24C3.654544 .24 4.985453-1.243636 4.985453-3.58909C4.985453-6.523635 3.730908-7.265453 2.759999-7.265453C2.159999-7.265453 1.625454-7.069089 1.156363-6.57818C.709091-6.087271 .458182-5.629089 .458182-4.810908C.458182-3.447272 1.418181-2.378181 2.639999-2.378181C3.305454-2.378181 3.752726-2.836363 4.003635-3.46909ZM2.650908-2.62909C2.476363-2.62909 1.974545-2.62909 1.636363-3.316363C1.44-3.719999 1.44-4.265453 1.44-4.799999C1.44-5.389089 1.44-5.901817 1.66909-6.305453C1.963636-6.850907 2.378181-6.992725 2.759999-6.992725C3.261817-6.992725 3.621817-6.621816 3.807272-6.130907C3.938181-5.781817 3.981817-5.094544 3.981817-4.592726C3.981817-3.687272 3.610908-2.62909 2.650908-2.62909Z"
                />
                <path
                  id="g5-61"
                  d="M7.494543-3.567272C7.65818-3.567272 7.865452-3.567272 7.865452-3.785453S7.65818-4.003635 7.505452-4.003635H.970909C.818182-4.003635 .610909-4.003635 .610909-3.785453S.818182-3.567272 .981818-3.567272H7.494543ZM7.505452-1.450909C7.65818-1.450909 7.865452-1.450909 7.865452-1.66909S7.65818-1.887272 7.494543-1.887272H.981818C.818182-1.887272 .610909-1.887272 .610909-1.66909S.818182-1.450909 .970909-1.450909H7.505452Z"
                />
                <path
                  id="g0-46"
                  d="M2.585454-.850909C2.585454-1.32 2.203636-1.701818 1.734545-1.701818S.883636-1.32 .883636-.850909S1.265454 0 1.734545 0S2.585454-.381818 2.585454-.850909Z"
                />
                <path
                  id="g0-54"
                  d="M2.007272-3.730908V-3.938181C2.007272-5.181817 2.236363-5.650908 2.312727-5.814544C2.574545-6.327271 3.163635-6.719998 3.82909-6.719998C4.025453-6.719998 4.396362-6.687271 4.647271-6.436362C4.254544-6.381816 4.058181-6.087271 4.058181-5.759998C4.058181-5.389089 4.30909-5.083635 4.734544-5.083635S5.421817-5.367271 5.421817-5.781817C5.421817-6.45818 4.963635-7.145453 3.807272-7.145453C2.323636-7.145453 .523636-6.207271 .523636-3.458181C.523636-2.596363 .621818-1.723636 1.069091-.970909C1.581818-.141818 2.410908 .12 3.174545 .12C4.690908 .12 5.73818-.774545 5.73818-2.236363C5.73818-3.763635 4.636362-4.559999 3.316363-4.559999C3.076363-4.559999 2.465454-4.559999 2.007272-3.730908ZM3.152726-.349091C2.585454-.349091 2.290908-.774545 2.203636-.949091C2.02909-1.298181 2.02909-2.094545 2.02909-2.290908C2.02909-3.654544 2.618181-4.167272 3.218181-4.167272C4.254544-4.167272 4.254544-3.34909 4.254544-2.258181C4.254544-1.156363 4.254544-.349091 3.152726-.349091Z"
                />
                <path
                  id="g0-57"
                  d="M4.254544-3.316363V-3.076363C4.254544-2.279999 4.156362-1.636363 3.992726-1.265454C3.807272-.84 3.338181-.349091 2.585454-.349091C2.38909-.349091 1.996363-.370909 1.66909-.589091C1.799999-.621818 2.203636-.774545 2.203636-1.265454C2.203636-1.636363 1.952727-1.941818 1.527272-1.941818S.84-1.658181 .84-1.243636C.84-.501818 1.396363 .12 2.607272 .12C4.025453 .12 5.73818-.796363 5.73818-3.58909C5.73818-4.319999 5.73818-7.145453 3.152726-7.145453C1.603636-7.145453 .523636-6.261816 .523636-4.799999C.523636-3.294545 1.603636-2.476363 2.945454-2.476363C3.327272-2.476363 3.839999-2.552727 4.254544-3.316363ZM3.043636-2.86909C2.007272-2.86909 2.007272-3.687272 2.007272-4.77818C2.007272-5.847271 2.007272-6.719998 3.174545-6.719998C3.818181-6.719998 4.06909-6.141816 4.112726-6.032726C4.243635-5.672726 4.243635-5.039999 4.243635-4.66909C4.243635-3.981817 3.959999-2.86909 3.043636-2.86909Z"
                />
                <path
                  id="g1-113"
                  d="M5.061817 17.563631L2.716363 9.381816L1.2 11.159997L1.374545 11.323633L2.116363 10.450906L4.625453 19.199995C4.985453 19.199995 4.996362 19.199995 5.083635 18.938177L11.072724 0C11.12727-.163636 11.12727-.207273 11.12727-.218182C11.12727-.338182 11.039997-.436364 10.909088-.436364C10.745452-.436364 10.701815-.294545 10.658179-.152727L5.061817 17.563631Z"
                />
              </defs>
              <g id="page1" transform="matrix(1.13 0 0 1.13 -80.23 -66.034914)">
                <use x="70.734745" y="72.113683" xlinkHref="#g3-82" />
                <use x="79.102182" y="72.113683" xlinkHref="#g3-83" />
                <use x="86.420361" y="72.113683" xlinkHref="#g3-66" />
                <use x="98.272581" y="72.113683" xlinkHref="#g5-61" />
                <use x="109.787705" y="72.113683" xlinkHref="#g5-40" />
                <use x="114.030143" y="72.113683" xlinkHref="#g5-52" />
                <use x="119.484708" y="72.113683" xlinkHref="#g5-57" />
                <use x="127.36347" y="72.113683" xlinkHref="#g2-3" />
                <use x="135.242233" y="72.113683" xlinkHref="#g5-52" />
                <use x="140.696797" y="72.113683" xlinkHref="#g5-41" />
                <use x="144.939236" y="72.113683" xlinkHref="#g3-61" />
                <use x="150.3938" y="58.656017" xlinkHref="#g1-113" />
                <rect
                  x="161.302919"
                  y="58.219665"
                  height=".436352"
                  width="72.494853"
                />
                <use x="161.302919" y="72.113683" xlinkHref="#g5-40" />
                <use x="165.545357" y="63.089449" xlinkHref="#g2-112" />
                <rect
                  x="174.636281"
                  y="62.653097"
                  height=".436352"
                  width="10.909103"
                />
                <use x="174.636281" y="72.113683" xlinkHref="#g5-52" />
                <use x="180.090846" y="72.113683" xlinkHref="#g5-57" />
                <use x="187.969582" y="72.113683" xlinkHref="#g2-3" />
                <use x="195.848344" y="72.113683" xlinkHref="#g5-52" />
                <use x="201.302909" y="72.113683" xlinkHref="#g5-41" />
                <use x="205.545347" y="68.962171" xlinkHref="#g4-50" />
                <use x="212.70186" y="72.113683" xlinkHref="#g5-43" />
                <use x="223.610934" y="72.113683" xlinkHref="#g5-51" />
                <use x="229.065499" y="68.962171" xlinkHref="#g4-50" />
                <use x="236.828019" y="72.113683" xlinkHref="#g5-61" />
                <use x="248.343142" y="72.113683" xlinkHref="#g0-54" />
                <use x="254.615834" y="72.113683" xlinkHref="#g0-46" />
                <use x="258.100663" y="72.113683" xlinkHref="#g0-57" />
                <use x="264.373355" y="72.113683" xlinkHref="#g0-54" />
              </g>
            </svg>
          </li>
        </ul>
        <h3>Finalement</h3>
        <p>
          En augmentant le gain…
          <ul>
            <li>
              Nous nous approchons du RSB idéal qui est de 7. Il ne sera
              toutefois jamais atteint car d’autres bruits entreront en jeu
              comme évoqué précédemment : bruit de quantification, bruit
              thermique.
            </li>
            <li>
              Le RSB devient meilleur.{" "}
              <strong>
                Ce qui explique pourquoi la vignette du début d’article prise à
                12800 ISO est moins bruitée que la vignette prise à 400 ISO.
              </strong>
            </li>
          </ul>
        </p>
      </Section>
      <Section titre="Conclusion">
        <p>
          <strong>Retenez ces éléments :</strong>
          <ul>
            <li>
              Nous avons vu que monter en ISO améliore le rapport signal sur
              bruit. Pourtant lorsque l’on réalise cette manipulation sur nos
              appareils on constate exactement l’inverse ! L’explication est la
              suivante : votre appareil a juste posé moins longtemps pour éviter
              de saturer ses photosites. Par conséquent, moins de signal a été
              reçu et donc le rapport signal sur bruit est devenu moins bon.
            </li>
            <li>
              Montez en ISO si vous n’avez pas le choix (attention aux fortes
              luminosités qui pourraient se trouver dans votre image)
            </li>
            <li>
              Surveillez la partie droite de votre histogramme et assurez-vous
              de ne pas trop saturer les hautes lumières.{" "}
              <strong>C’est le plus important</strong> : je préfère avoir une
              image un peu plus bruitée mais avec peu de zones surexposées que
              l’inverse. Gardez tout de même en tête qu’en photo de nuit, vous
              aurez toujours des lumières saturées. Il vous faut donc trouver un
              compromis.
            </li>
          </ul>
        </p>
        <p>
          Enfin, maintenant que vous savez décrypter les graphiques de
          comportement des capteurs vous pouvez trouver des valeurs idéales pour
          votre boîtier grâce au remarquable travail de{" "}
          <Lien urlExterne="https://www.photonstophotos.net/Charts/RN_e.htm">
            Bill Claff
          </Lien>{" "}
          qui a réalisé énormément de mesures sur des boîtiers du marché. A
          titre personnel je travaille la plupart du temps à 800 ISO en ciel
          profond, et entre 1600 et 3200 ISO en paysage nocturne avec mon Canon
          EOS 6D.
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
